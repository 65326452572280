import React, { useEffect, useState } from 'react';
import './ProfileManagement.css';
import { useProfiles } from "../hooks/useProfiles";
import { useUsers } from "../hooks/useUsers";
import CreateProfile from '../dashComponents/CreateProfile';
import { useAccount } from '../hooks/useAccount';
import { useAuthContext } from '../context/useAuthContext';
import { eventURL } from '../definitions';

const wellnessCheckOptions = [
    { value: 0, label: 'None' },
    { value: 4, label: '4 hours' },
    { value: 12, label: '12 hours' },
    { value: 24, label: '24 hours' }
];

const ProfileManagement = () => {
    const [profiles, setProfiles] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const { getAllProfilesWithUsers, updateProfile, sendWellnessEmail } = useProfiles();
    const { updateUserById } = useUsers();
    const [view, setView] = useState("VIEW");
    const { user, dispatch } = useAuthContext();
    const { logout } = useAccount();

    useEffect(() => {
        let eventSource;
        let userEventSource;

        const fetchProfiles = () => {
            eventSource = new EventSource(`${eventURL}/profiles/profilesusers?page=${currentPage}&limit=10`);

            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.error) {
                    console.error('Failed to fetch profiles:', data.message);
                    return;
                }
                setProfiles(data.data.profiles);
                setTotalPages(data.data.totalPages);
            };

            eventSource.onerror = (error) => {
                console.error('EventSource failed:', error);
                eventSource.close();
            };
        };

        if (!user || !user.authorized) {
            logout();
            return;
        }
        const currentTime = Date.now() / 1000;
        if (currentTime >= user.exp) {
            logout();
            return;
        }

        fetchProfiles();

        userEventSource = new EventSource(`${eventURL}/users/exp/sse/${user.id}`);

        userEventSource.onmessage = (event) => {
            const userjson = JSON.parse(event.data);
            if (Date.now() / 1000 > userjson.refreshExp) {
                const newUser = { ...user, exp: userjson.refreshExp };
                dispatch({ type: "UPDATE_USER", payload: newUser });
            }
        };

        userEventSource.onerror = (error) => {
            userEventSource.close();
        };

        window.onbeforeunload = () => {
            if (eventSource) {
                eventSource.close();
            }
            if (userEventSource) {
                userEventSource.close();
            }
        }

        return () => {
            if (eventSource) {
                eventSource.close();
            }
            if (userEventSource) {
                userEventSource.close();
            }
        };

    }, [currentPage, user]);

    if (!user || !user.authorized || (Date.now() / 1000 >= user.exp)) {
        return <div></div>;
    }

    if (!profiles.length) {
        return <div>Loading Profiles...</div>;
    }

    const handleBlockUser = (id, block) => {
        if (block) {
            updateUserById(id, { "accountBlocked": block, "refreshExp": 0, "authorized": 0 });
        } else {
            updateUserById(id, { "accountBlocked": block });
        }
    };

    const handleResetUser = (id) => {
        updateUserById(id, { "registered": 0 });
    };

    const handleWellnessCheckChange = (id, value) => {
        updateProfile(id, { wellnessCheckFrequency: value, lastWellnessCheck: Date.now() });
    };

    const handleManualCheck = async (id) => {
        updateProfile(id, { lastWellnessCheck: Date.now(), wellnessCheck: true });
        await sendWellnessEmail(id);
    };

    const handleManualClear = async (id) => {
        updateProfile(id, { lastWellnessCheck: Date.now(), wellnessCheck: false, wellnessCheckReported: false });
    };

    const getWellnessCheckStatus = (profile) => {
        const { lastWellnessCheck, wellnessCheck } = profile;

        if (!wellnessCheck) {
            return { status: 'Status OK', color: 'green' };
        } else if (profile.wellnessCheckReported) {
            return { status: 'No Response', color: 'red' };
        } else {
            return { status: 'Waiting for Response', color: 'yellow' };
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage !== currentPage) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div>
            <div style={{ display: 'flex' }}>
                {view === "VIEW" && <h1 style={{fontWeight: 'bold'}}>All Profiles</h1>}
                {view === "CREATE" && <h1 style={{fontWeight: 'bold'}}>Create Profile</h1>}
                
                {view === "VIEW" && user.role === "admin" && <button onClick={() => setView("CREATE")}>Create Profile</button>}
                {view === "CREATE" && user.role === "admin" && <button onClick={() => setView("VIEW")}>All Profiles</button>}
            </div>
            {view === "CREATE" && <CreateProfile />}
            {view === "VIEW" &&
                <div>
                    <table className="profiles-table">
                        <thead>
                            <tr>
                                <th>User State</th>
                                <th>User Role</th>
                                <th>Department</th>
                                <th>Wellness Check</th>
                                <th>Account Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {profiles.map((profile) => {
                                const wellnessStatus = getWellnessCheckStatus(profile);
                                return (
                                    <tr key={profile.id}>
                                        <td>
                                            {profile.profileOnly ? '👤' :
                                            (profile.user && profile.user.lastTimestamp > Date.now() - 30000 ? <span style={{ fontSize: 20 }} className="icon green">●</span> : <span style={{ fontSize: 20 }} className="icon gray">●</span>)}
                                            <img src={profile.profilePicUrl} alt="Profile" className="profile-pic" />
                                            {profile.firstName} {profile.lastName} {user.profileRefId === profile.id && "(You)"}
                                        </td>
                                        <td>
                                            <label>{profile.userRole}</label>
                                        </td>
                                        <td>
                                            <label>{profile.groupName}</label>
                                        </td>
                                        { user.role === "admin" &&  
                                        <td>
                                            <select value={profile.wellnessCheckFrequency || 0} disabled={profile.id === user.profileRefId} onChange={(e) => handleWellnessCheckChange(profile.id, e.target.value)}>
                                                {wellnessCheckOptions.map(option => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                            <button onClick={() => handleManualCheck(profile.id)} disabled={profile.id === user.profileRefId || profile.wellnessCheck}>Manual Check</button>
                                            <button onClick={() => handleManualClear(profile.id)} disabled={profile.id === user.profileRefId || !profile.wellnessCheck}>Manual Clear</button>
                                            <span className={`bubble ${wellnessStatus.color}`}>{wellnessStatus.status}</span>
                                            {!profile.wellnessCheckFrequency ? <label>No planned wellness check!</label> : (
                                                <label>
                                                    Next Wellness Check: {new Date((60 * 60 * 1000 * profile.wellnessCheckFrequency) + profile.lastWellnessCheck).toLocaleString()}
                                                </label>
                                            )}
                                        </td>
                                        }
                                        { user.role !== "admin" &&  
                                        <td>
                                            <span className={`bubble ${wellnessStatus.color}`}>{wellnessStatus.status}</span>
                                            {!profile.wellnessCheckFrequency ? <label>No planned wellness check!</label> : (
                                                <label>
                                                    Next Wellness Check: {new Date((60 * 60 * 1000 * profile.wellnessCheckFrequency) + profile.lastWellnessCheck).toLocaleString()}
                                                </label>
                                            )}
                                        </td>
                                        }
                                        { user.role === "admin" &&                                        <td>
                                            <button
                                                onClick={() => handleBlockUser(profile.userRefId, !profile.user.accountBlocked)}
                                                disabled={profile.id === user.profileRefId || profile.userRole === "contactOnly" }

                                            >
                                                {profile.user.accountBlocked && <div>Unblock</div>}
                                                {!profile.user.accountBlocked && <div>Block</div>}

                                            </button>
                                            <button
                                                onClick={() => handleResetUser(profile.userRefId)}
                                                disabled={!profile.user.registered || profile.id === user.profileRefId || profile.userRole === "contactOnly"}
                                            >
                                                <div>Reset Credentials</div>

                                            </button>
                                        </td> }
                                        { user.role !== "admin" && <td>PERMISSION DENIED</td>}

                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                disabled={currentPage === index + 1}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>}
        </div>
    );
}

export default ProfileManagement;
