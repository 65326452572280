import { useEffect, useState } from "react"
import InputField from "../components/InputField"
import { useAuthContext } from "../context/useAuthContext"
import { useProfiles } from "../hooks/useProfiles"
import { useForms } from "../hooks/useForms"
import { useThreats } from "../hooks/useThreats"


export const CreateReport = () => {
    const { user} = useAuthContext()
    const { getAllProfiles, updateProfileRecentActivity, updateProfileUnreadNotifications, updateProfileTasks} = useProfiles()
    const { createThreat} = useThreats()
    const { getAllForms } = useForms()

    const severity = ["Low", "Medium", "High"]

    const [profiles, setProfiles] = useState([])
    const [forms, setForms] = useState([])
    const [error, setError] = useState("")
    const [errorList, setErrorList] = useState([])
    const [statusLabel, setStatusLabel] = useState("")
    const [color, setColor] = useState("")
    const [selectedAudit, setSelectedAudit] = useState(forms.length > 0 ? forms[0].formName : '')
    const [selectedSeverity, setSelectedSeverity] = useState(severity[0])
    const [profile, setProfile] = useState({})
    const [time, setTime] = useState(Date.now())
    const [paramData, setParamData] = useState({})
    const [uploadData, setUploadData] = useState({
        username: user.username,
        createdAt: Date.now(),
        lastUpdated: Date.now(),
        incidentTime: "",
        title: "",
        description: "",
        entity: "Test",
        entityName: "Test",
        threatType: selectedAudit,
        severity: selectedSeverity,
        allowedAccessIds: [user.email],
        history: [],
        completed: false,
    })

    useEffect(() => {
        const fetchProfiles = async () => {
            const json = await getAllProfiles()
            if (json) {
                if (!json.error && json.data.profiles.length > 0) {
                    setProfiles(json.data.profiles)
                    setProfile(json.data.profiles[0])
                }
            }
        }
        const fetchForms = async () => {
            const json = await getAllForms()
            if (json) {
                if (!json.error) {
                    setForms(json.data.forms)
                    setSelectedAudit(json.data.forms[0].formName)
                    setUploadData({ ...uploadData, ["threatType"]: json.data.forms[0].formName })

                }
            }
        }
        fetchProfiles()
        fetchForms()
    }, [])


    const handleSend = async () => {
        let missingInput = false
        let status = " Missing "
        let tempErrorList = []
        const ignore = ["history", "completed"]
        for (let key in uploadData) {
        if (!ignore.includes(key)) {
            if (uploadData[key] === "") {
            missingInput = true
            status += " " + key + ","
            tempErrorList.push(key)
            }
        }
        }
        for (let key in paramData) {
            if (paramData[key] === "") {
                missingInput = true
                status += " " + key + ","
                tempErrorList.push(key)
            }
        }
        if (!missingInput) {
            setError(false)
            setColor("green")
            setStatusLabel(" Success!")
            const json = await createThreat(profile.id, {uploadData, paramData})
            const tempThreat = json.data.threat
            updateProfileUnreadNotifications(user.profileRefId, {message: `Need to review new ${tempThreat.threatType} threat ${tempThreat.title}`,  department: tempThreat.threatType, link: `/threats/${tempThreat.id}`});
            updateProfileRecentActivity(user.profileRefId, { type: 'update', message: `Created new ${tempThreat.threatType} threat ${tempThreat.title}`,  department: tempThreat.threatType, link: `/threats/${tempThreat.id}` });
            setUploadData({
                username: user.username,
                createdAt: Date.now(),
                lastUpdated: Date.now(),
                incidentTime: "",
                title: "",
                description: "",
                entity: "Test",
                entityName: "Test",
                threatType: forms.length > 0 ? forms[0].formName : '',
                severity: severity[0],
                allowedAccessIds: [user.email],
                history: [],
                completed: false,
            })
            setParamData({})
            setSelectedAudit(forms.length > 0 ? forms[0].formName : '')
            setSelectedSeverity(severity[0])
        } else {
            setError(true)
            setErrorList(tempErrorList)
            setColor("red")
            setStatusLabel(status)
        }
    }


    const handleTextChange = (e) => {
        setUploadData({ ...uploadData, [e.target.name]: e.target.value })
        let foundIndex = errorList.indexOf(e.target.name)
        if (foundIndex !== -1) {
        errorList.splice(foundIndex, 1)
        setErrorList(errorList)
        }
    }

    const handleSpecificTextChange = (e) => {
        setParamData({ ...paramData, [e.target.name]: e.target.value })
        let foundIndex = errorList.indexOf(e.target.name)
        if (foundIndex !== -1) {
        errorList.splice(foundIndex, 1)
        setErrorList(errorList)
        }
    }

    const handleChange = (e) => {
        setSelectedAudit(e.target.value)
        setUploadData({ ...uploadData, [e.target.name]: e.target.value })
    }


    if (!Array.isArray(profiles) || !Array.isArray(forms) || !forms || forms.length === 0) {
        return <div>Loading...</div>
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", marginLeft: "5dvw", width: "60dvw" }}>
        <h2 style={{ marginBottom: 20 }}>General Info</h2>
        <div style={{ display: "flex", marginBottom: 10 }}>
            <label htmlFor="threatDropdown" style={{ width: "30%" }}>
            Threat Type:
            </label>
            <select id="threatDropdown" name="threatType" value={uploadData.threatType} onChange={handleChange}>
            {forms.map((audit, index) => (
                <option key={index} value={audit.formName}>
                {audit.formName}
                </option>
            ))}
            </select>
        </div>
        <div style={{ display: "flex" }}>
            <label htmlFor="severityDropdown" style={{ width: "30%" }}>
            Threat Severity
            </label>
            <select id="severityDropdown" name="severity" value={uploadData.severity} onChange={(e) => handleTextChange(e)}>
            {severity.map((audit, index) => (
                <option key={index} value={audit}>
                {audit}
                </option>
            ))}
            </select>
        </div>
        <div style={{ height: 20 }}> </div>
        <InputField label={"text"} disabled={true} defaultValue={user.username} text="Reporting Officer:" name="username" error={error} errorList={errorList}/>
                <InputField label={"text"} disabled={true} text="Date Reported:" defaultValue={new Date(time).toLocaleString()} name="createdAt" onChange={handleTextChange} error={error} errorList={errorList}/>
                <InputField label={"text"} text="Title:" name="title" defaultValue={uploadData.title} onChange={handleTextChange} error={error} errorList={errorList}/>
                <InputField label={"text"} text="Threat Description:" defaultValue={uploadData.description}  name="description" onChange={handleTextChange} error={error} errorList={errorList}/>
                <InputField label={"date"} text="Original Date/Time/Place:" defaultValue={uploadData.incidentTime} name="incidentTime" onChange={handleTextChange} error={error} errorList={errorList}/>
            <h2 style={{ marginBottom: 20 }}>Specific Info</h2>

        {forms
            .find((audit) => audit.formName === selectedAudit)
            ?.fields.map((field, index) => (
            <InputField
                key={index}
                label={"text"}
                text={field.label}
                name={field.name}
                defaultValue={paramData[field.name]}
                onChange={handleSpecificTextChange}
                error={error}
                errorList={errorList}
                />
            ))}
            <button className="upload-button" onClick={handleSend}>
            Create Report
            </button>
            <label style={{ color: `${color}` }}>{statusLabel}</label>
        </div>
    )
}

