import { useAccount } from "./useAccount"

export const useApi = () => {
    const {checkLogout} = useAccount()

    const scanOCR = async (fileId) => {
        checkLogout()
        const response = await fetch(`/api/vision/${fileId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        return await response.json()
    }

    const detectThreats = async (fileIds) => {
        checkLogout()
        const response = await fetch(`/api/threats`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({fileIds})
        })
        return await response.json()
    }

    const detectFileThreats = async (fileId) => {
        checkLogout()
        const response = await fetch(`/api/fileThreats/${fileId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        return await response.json()
    }

    const submitPrompt = async (prompt) => {
        checkLogout()
        const response = await fetch(`/api/`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({prompt: prompt})
        })
        return await response.json()
    }

    const getMapAuth = async () => {
        checkLogout()
        try {
            const response = await fetch(`/api/map`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json'

                }
            })
            return await response.json()
        } catch (error) {
            return {}
        }
    }

    const getAllActiveUsers = async () => {
        checkLogout()
        const response = await fetch(`/users/active`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        const activeUsers = await response.json()
        return activeUsers
    }

    const getKeywords = async(fileId) => {
        checkLogout()
        const response = await fetch(`/api/keywords/${fileId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        return await response.json()
    }

    const sendMapAlert = async(profileId)=> {
        checkLogout()
        await fetch(`/api/mapAlert/${profileId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        })
    }

    return {submitPrompt, getMapAuth, getAllActiveUsers, scanOCR, getKeywords, detectFileThreats, detectThreats, sendMapAlert}
}

