import { useEffect, useState } from "react";

const Timer = ({ expirationTime }) => {
    const calculateTimeLeft = () => {
        const difference = expirationTime * 1000 - Date.now();
        let timeLeft = {};

        if (difference > 0) {
        timeLeft = {
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
        } else {
        timeLeft = {
            minutes: 0,
            seconds: 0
        };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const interval = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(interval);
    }, [expirationTime]);

    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
        <h3>{"Time Remaining: "}</h3>
        {timeLeft.minutes}:{timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
        </div>
    );
};

export default Timer;
