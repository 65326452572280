import React, { useState } from 'react';
import './EmergencyForm.css';
import { useAuthContext } from '../context/useAuthContext';

const EmergencyForm = ({ onSubmit, onClose, panicType }) => {
    const {user} = useAuthContext()
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [severity, setSeverity] = useState(panicType === "watch" ? "Medium" : "High");
    const [threatType, setThreatType] = useState('Emergency');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ username: user.username, title, description, severity, threatType, panicType, threatSpecificData: {"Panic Type": panicType} });
    };

    return (
        <div className="emergency-form-backdrop">
            <div className="emergency-form-container">
                <h2>Emergency Alert</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="severity">Severity</label>
                        <select
                            id="severity"
                            defaultValue={panicType === "watch" ? "Medium" : "High"}
                            onChange={(e) => setSeverity(e.target.value)}
                            required
                        >
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="threatType">Threat Type</label>
                        <input
                            type="text"
                            id="threatType"
                            value={threatType}
                            onChange={(e) => setThreatType(e.target.value)}
                            required
                            disabled={true}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="btn btn-primary">Submit</button>
                        <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EmergencyForm;
