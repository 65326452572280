import { useState, useEffect, useReducer } from "react";
import { useAccount } from "../hooks/useAccount";
import { useAuthContext } from "../context/useAuthContext";
import Timer from "../components/Timer";
import './Authorization.css';
import { useNavigate } from "react-router-dom";
import { eventURL } from '../definitions';

const Authorization = () => {
    const { user, dispatch } = useAuthContext();
    const { checkAuthorized, logout } = useAccount();
    const navigate = useNavigate();
    const [MFACode, setMFACode] = useState('');
    const [message, setMessage ] = useState("")

    useEffect(()=> {
        const eventSource = new EventSource(`${eventURL}/authorize/approvers/${user.id}`);

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            dispatch({type: 'LOGIN', payload: {...data, exp: user.exp}});
        };

        eventSource.onerror = (error) => {
            console.error('EventSource failed:', error);
            eventSource.close();
        };

        window.onbeforeunload = () => {
            eventSource.close();
        };

        return () => {
            eventSource.close();
        };
    }, [])

    useEffect(() => {
        if (user && user.authorized) {
            navigate('/dashboard');
            return;
        }

        if (user && user.verified && user.login) {
            if (user.approvers.length === 0 && user.approvals.includes('reject')) {
                navigate('/');
                return;
            }
            if (user.authorized) {
                const currentTime = Date.now() / 1000;
                if (currentTime >= user.exp) {
                    logout();
                    return;
                } else {
                    navigate('/dashboard');
                    return;
                }
            } 
        } else {
            navigate('/');
        }
    }, [user]);

    const handleCheckAuthorization = async () => {
        try {
            const json = await checkAuthorized(MFACode);
            setMessage(json.message)
            if (json.data && json.data.user) {
                dispatch({ type: 'LOGIN', payload: json.data.user });
            }
            if (json.redirectTo) {
                navigate(json.redirectTo);
                return;
            }
        } catch (error) {
            logout();
        }
    };

    if (!user || user.authorized) {
        return <div></div>;
    }

    return (
        <div className="authorization-container" style={{ display: "flex", flexDirection: "row" }}>
            <div></div>
            <div className="authorization-container">
                <Timer expirationTime={user.exp} />
                <div className="approvals-container">
                    <div className="verification-item">
                        <label>MFA Code</label>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <input
                                type="text"
                                placeholder="Enter MFA Code"
                                value={MFACode}
                                onChange={(e) => setMFACode(e.target.value)}
                                disabled={user.approvers.length !== 0}
                            />
                            <button disabled={user.approvers.length !== 0} onClick={handleCheckAuthorization}>
                                Check MFA Code
                            </button>
                        </div>
                        {user.approvers.length === 0 && <div style={{ color: "red" }}>{message}</div>}
                    </div>
                    {Array.from({ length: user.maxApprovers }, (_, i) => (
                        <div key={i} className={`approver-box ${i < user.approvals.length ? user.approvals[i] : 'pending'}`}>
                            {`Approver ${i + 1}`}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Authorization;
