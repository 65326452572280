import { createContext, useReducer, useState } from 'react'
export const AuthContext = createContext()

const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            localStorage.setItem('user', JSON.stringify(action.payload))
            return { user: action.payload }
        case 'LOGOUT':
            localStorage.removeItem('user')
            return { user: null }
        case 'UPDATE_USER':
            localStorage.setItem('user', JSON.stringify(action.payload))
            return { user: action.payload }
        default:
            return state
    }
}

const AuthContextProvider = ({ children }) => {
    const user = JSON.parse(localStorage.getItem('user')) || null
    const [metadata, _setMetadata] = useState({})
    const [state, dispatch] = useReducer(authReducer, { user: user })

    const setMetadata = (metadata) => {
        _setMetadata(metadata)
    }
    return (
        <AuthContext.Provider value={{...state, dispatch, metadata, setMetadata}}>
            { children }
        </AuthContext.Provider>
    )
}

export default AuthContextProvider