import { useAuthContext } from '../context/useAuthContext'
import { useAccount } from './useAccount'

export const useProfiles = () => {
    const { user } = useAuthContext()
    const { checkLogout } = useAccount()
    const createProfile = async (profileData) => {
        checkLogout()

        const response = await fetch(`/profiles/create`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(profileData)
        })
        return await response.json()
    }

    const getProfile = async () => {
        checkLogout()
        const response = await fetch(`/profiles/${user.profileRefId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        return await response.json()
    }

    const getProfileById = async (profileId) => {
        checkLogout()
        const response = await fetch(`/profiles/specific/${profileId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        return await response.json()
    }

    const getAllProfiles = async (page = 1, limit = 10) => {
        checkLogout()
        const response = await fetch(`/profiles?page=${page}&limit=${limit}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }
    
    const getProfilesByDepartment = async(department)=> {
        checkLogout()
        const response = await fetch(`/profiles/department`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({department})
        })
        return await response.json()
    }

    const updateProfile = async (profileId, profileData) => {
        checkLogout()
        const response = await fetch(`/profiles/update/${profileId}`, {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({profileData})
        })
        return await response.json()
    }

    const updateProfileThreatsAccess = async (profileId, threatsManagingData, threatId, update) => {
        checkLogout()
        const response = await fetch(`/profiles/threats/${profileId}`, {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({threatsManagingData, threatId, update})
        })
        return await response.json()
    }

    const updateProfileFiles = async (profileId, fileIds) => {
        checkLogout()
        const response = await fetch(`/profiles/files/${profileId}`, {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(fileIds)
        })
        return await response.json()
    }

    const updateProfileRecentActivity = async (profileId, recentActivity) => {
        checkLogout()
        const response = await fetch(`/profiles/activity/${profileId}`, {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({recentActivity})
        })
        return await response.json()
    }

    const updateProfileUnreadNotifications = async (profileId, notifications) => {
        checkLogout()
        const response = await fetch(`/profiles/unread/${profileId}`, {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({notifications})
        })
        return await response.json()
    }

    const updateProfileReadNotifications = async (profileId) => {
        checkLogout()
        const response = await fetch(`/profiles/read/${profileId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }
        })
        return await response.json()
    }

    const updateProfileTasks = async (profileId, tasks) => {
        checkLogout()
        const response = await fetch(`/profiles/tasks/${profileId}`, {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tasks)
        })
        return await response.json()
    }

    const sendWellnessEmail = async (profileId) => {
        checkLogout()
        const response = await fetch(`/profiles/wellness/${profileId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }
        })
        return await response.json()
    }
 
    const confirmWellnessEmail = async (token) => {
        const response = await fetch(`/profiles/confirm/${token}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            },
        })
        return await response.json()
    }

    const getAllProfilesWithUsers = async (page, limit) => {
        const response = await fetch(`/profiles/profilesusers?page=${page}&limit=${limit}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            },
        })
        return await response.json()
    }

    return {getAllProfiles, createProfile, updateProfileThreatsAccess, updateProfileFiles, updateProfileRecentActivity, getProfile,
        updateProfileReadNotifications, updateProfileUnreadNotifications, updateProfileTasks, updateProfile, getProfilesByDepartment, getProfileById,
        sendWellnessEmail, confirmWellnessEmail, getAllProfilesWithUsers
    }
}

