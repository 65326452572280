import { DashboardContext } from "./DashboardContext"
import { useContext} from "react"

export const useDashContext = () => {
    const context = useContext(DashboardContext)
    
    if (!context) {
        throw Error('useDashContext must be used inside an DashboardContextProvider')
    }

    return context
}