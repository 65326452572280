import React from 'react';
import {  useNavigate } from 'react-router-dom'; 

export const Tile = ({ threat }) => {
    const navigate = useNavigate();
    const map = { "Low": "yellow", "Medium": "orange", "High": "red", "low": "yellow", "medium": "orange", "high": "red" };
    return (
        <tr  onClick={()=>navigate(`/threat/${threat.id}`)}>
            <td style={{ backgroundColor: map[threat.severity] }}>{threat.severity}</td>
            <td>{threat.status}</td>
            <td>{threat.createdAt ? new Date(threat.createdAt).toLocaleString() : "None"}</td>
            <td>{threat.threatType}</td>
            <td>{threat.username}</td>
            <td>{threat.title}</td>
            <td>{threat.description}</td>
        </tr>
    );
};

export default Tile;
