import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Verify from './pages/Verify';
import { useAuthContext } from './context/useAuthContext';
import Authorization from './pages/Authorization';
import Dashboard from './pages/Dashboard';
import EmailAuthorization from './pages/EmailAuthorization';
import Navbar from './components/Navbar';
import { useEffect, useState } from 'react';
import Map from './pages/Map';
import { ViewThreats } from './pages/ThreatManagement';
import Sidebar from './components/Sidebar';
import ProfileManagement from './pages/ProfileManagement';
import LiveFeed from './pages/LiveFeed';
import { useMetadata } from './hooks/useMetadata';
import { useHeartbeat } from './hooks/useIP';
import Database from './pages/Database';
import ThreatManager from './pages/ThreatManager';
import EmailWellnessCheck from './pages/EmailWellnessCheck';

function App() {
    const { metadata, setMetadata } = useAuthContext();
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const { getMetadata } = useMetadata();
    const [loading, setLoading] = useState(false);

    useHeartbeat()
    
    useEffect(() => {
        let isMounted = true;
        const MAX_RETRIES = 10; 
        let retryCount = 0;

        const fetchMetadata = async () => {
            while (Object.keys(metadata).length === 0 && retryCount < MAX_RETRIES) {
                try {
                    const tempMetadata = await getMetadata();
                    if (isMounted) {
                        setMetadata(tempMetadata);
                        if (Object.keys(tempMetadata).length > 0) {
                            break; 
                        }
                    }
                } catch (error) {
                }
                retryCount += 1;
                await new Promise(resolve => setTimeout(resolve, 5000)); 
            }
        };

        fetchMetadata();

        return () => {
            isMounted = false; 
        };
    }, []);

    const buttons = [
        { redirectTo: "/dashboard", label: "Your Dashboard", role: "user" },
        { redirectTo: "/live", label: "Live Feed", role: "admin" },
        { redirectTo: "/map", label: "Global Agent Tracker", role: "admin" },
        { redirectTo: "/viewthreats", label: "Threat Management", role: "user" },
        { redirectTo: "/viewprofiles", label: "Profile Management", role: "user" },
        { redirectTo: "/database", label: "File Database", role: "user" },
    ];

    const changePage = (page) => (
        <div className="app-layout">
            <Sidebar
                buttons={buttons}
                isCollapsed={isSidebarCollapsed}
                toggleSidebar={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                loading={loading}
            />
            <div className={`main-content ${isSidebarCollapsed ? 'collapsed' : ''}`}>
                {page}
            </div>
        </div>
    );

    return (
        <div className="app">
            <Navbar />
            <div className="app-pages">
                <Routes>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/" element={<Login />} />
                    <Route path="/verify" element={<Verify />} />
                    <Route path="/emailauthorize/:token" element={<EmailAuthorization />} />
                    <Route path="/wellness/:token" element={<EmailWellnessCheck />} />
                    <Route path="/authorization" element={<Authorization />} />
                    <Route path="/dashboard" element={changePage(<Dashboard />)} />
                    <Route path="/live" element={changePage(<LiveFeed />)} />
                    <Route path="/map" element={changePage(<Map setLoading={setLoading} />)} />
                    <Route path="/viewthreats" element={changePage(<ViewThreats />)} />
                    <Route path="/threat/:id" element={changePage(<ThreatManager />)} />
                    <Route path="/viewprofiles" element={changePage(<ProfileManagement />)} />
                    <Route path="/database" element={changePage(<Database />)} />
                </Routes>
            </div>
        </div>
    );
}

export default App;
