import { useAccount } from './useAccount';


export const useThreats = () => {    const { checkLogout } = useAccount()
    
    const getAllThreats = async (page, limit) => {
        checkLogout()
        const response = await fetch(`/threats?page=${page}&limit=${limit}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    };
    
    const getThreatById = async (threatId) => {
        checkLogout()
        const response = await fetch(`/threats/specific/${threatId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        })
    return await response.json()
}

    const getThreatsByIds = async (threatIds) => {
        checkLogout()
        const response = await fetch(`/threats/ids`, {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({threatIds})
    })
    return await response.json()
}

    const createThreat = async (profileId, threatData) => {
        checkLogout()
        const response = await fetch(`/threats/${profileId}`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(threatData)
        })
        return await response.json()
    }

    const updateThreat = async (threat) => {
        checkLogout()
        const response = await fetch(`/threats/${threat.id}`, {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(threat)
        })
        return await response.json()
    }

    const sendPanicThreat = async (threatData) => {
        checkLogout()
        const response = await fetch(`/threats/panic`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({threatData})
        })
        return await response.json()
    }


    const updateThreatFiles = async (threatId, fileIds) => {
        checkLogout()
        const response = await fetch(`/threats/files/${threatId}`, {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(fileIds)
        })
        return await response.json()
    }

    return {getAllThreats, createThreat, updateThreatFiles, updateThreat, getThreatsByIds, getThreatById, sendPanicThreat}
}

