import React, { useEffect, useState } from 'react';
import './MapSidebar.css';
import { useAccount } from '../hooks/useAccount';
import { useAuthContext } from '../context/useAuthContext';
import { useUsers } from '../hooks/useUsers';
import { useNavigate } from 'react-router-dom';
import { eventURL } from '../definitions';

const MapSidebar = ({ isOpen, content, profile, onClose, handleRadiusChange, handleSendAlert }) => {
    const { user } = useAuthContext();
    const { clearPanicAlert } = useAccount();
    const { updateUserById } = useUsers();
    const [watchThreat, setWatchThreat] = useState(null);
    const [warningThreat, setWarningThreat] = useState(null);
    const [watchThreatRefId, setWatchThreatRefId] = useState(null);
    const [warningThreatRefId, setWarningThreatRefId] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const formatTimestamp = (timestamp) => {
        return new Date(timestamp).toLocaleString();
    };

    useEffect(() => {
        let eventSource;

        if (user && user.authorized) {
            eventSource = new EventSource(`${eventURL}/threats/map/${content.id}`);

            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.error) {
                    setError(data.message);
                    navigate("/threats");
                    return;
                }
                setWatchThreat(data.data.watchThreat);
                setWarningThreat(data.data.warningThreat);
                setWatchThreatRefId(data.data.watchThreatRefId);
                setWarningThreatRefId(data.data.warningThreatRefId);
            };

            eventSource.onerror = (error) => {
                console.error('EventSource failed:', error);
                eventSource.close();
            };

            window.onbeforeunload = () => {
                eventSource.close();
            };

            return () => {
                eventSource.close();
            };
        }
    }, [content, user, navigate]);

    const handleClearAlert = async (panicType) => {
        handleRadiusChange(0)
        await clearPanicAlert(panicType, content.id);
        if (panicType === "watch") {
            setWatchThreat(null);
            setWatchThreatRefId(null);
        } else if (panicType === "warning") {
            setWarningThreat(null);
            setWarningThreatRefId(null);
        }
    };

    const handleBlockUser = (id) => {
        updateUserById(id, { "accountBlocked": 1, "refreshExp": 0, "authorized": 0 });
    };

    return (
        <div className={`map-sidebar ${isOpen ? 'open' : ''}`}>
            <div className="map-sidebar-content">
                <button onClick={onClose} style={{ height: 30, width: 50 }}>Close</button>
                <h2 style={{ fontWeight: "bold", fontSize: 20 }}>SeeThreat Officer Info</h2>
                {profile ? (
                    <div>
                        <p>Name: {profile.firstName} {profile.lastName}</p>
                        <p>Email: {content.email}</p>
                        <p>Phone: {content.phoneNumber}</p>
                        <p>Last Location: {content.lastLocation ? `${content.lastLocation.lat}, ${content.lastLocation.lng}` : 'N/A'}</p>
                        <p>Last Active: {formatTimestamp(content.lastTimestamp)}</p>
                        {user.role === "admin" && (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <button
                                    onClick={() => handleClearAlert("watch")}
                                    disabled={!watchThreatRefId}
                                    style={{ height: 30, backgroundColor: "orange" }}
                                >
                                    Clear Watch
                                </button>
                                <button
                                    onClick={() => handleClearAlert("warning")}
                                    disabled={!warningThreatRefId}
                                    style={{ height: 30, backgroundColor: 'red' }}
                                >
                                    Clear Warning
                                </button>
                                <button
                                    onClick={() => handleBlockUser(content.id)}
                                    disabled={content.accountBlocked}
                                >
                                    Block
                                </button>
                                <div style={{ marginTop: 20 }}>
                                    <label>Radius (miles): </label>
                                    <select defaultValue={0} onChange={(e) => handleRadiusChange(Number(e.target.value))} disabled={!watchThreatRefId && !warningThreatRefId}>
                                        <option value={0}>0 miles</option>
                                        <option value={1609.34}>1 mile</option>
                                        <option value={8046.72}>5 miles</option>
                                        <option value={16093.4}>10 miles</option>
                                        <option value={40233.6}>25 miles</option>
                                        <option value={80467.2}>50 miles</option>
                                        <option value={160934}>100 miles</option>
                                    </select>
                                    <button onClick={handleSendAlert} style={{ marginLeft: 10 }} disabled={!watchThreatRefId && !warningThreatRefId}>
                                        Send Alert
                                    </button>
                                </div>
                            </div>
                        )}
                        {watchThreatRefId ? (
                            watchThreat && watchThreat.title ? (
                                <div>
                                    <h3>Watch Threat Details</h3>
                                    <p>Title: {watchThreat.title}</p>
                                    <p>Description: {watchThreat.description}</p>
                                    <p>Severity: {watchThreat.severity}</p>
                                    <p>Created At: {formatTimestamp(watchThreat.createdAt)}</p>
                                    <p>Incident Time: {formatTimestamp(watchThreat.incidentTime)}</p>
                                </div>
                            ) : (
                                <p style={{ fontSize: 14, fontStyle: "italic" }}>Waiting for watch threat details...</p>
                            )
                        ) : (
                            <p>Watch Status OK</p>
                        )}
                        {warningThreatRefId ? (
                            warningThreat && warningThreat.title ? (
                                <div>
                                    <h3>Warning Threat Details</h3>
                                    <p>Title: {warningThreat.title}</p>
                                    <p>Description: {warningThreat.description}</p>
                                    <p>Severity: {warningThreat.severity}</p>
                                    <p>Created At: {formatTimestamp(warningThreat.createdAt)}</p>
                                </div>
                            ) : (
                                <p style={{ fontSize: 14, fontStyle: "italic" }}>Waiting for warning threat details...</p>
                            )
                        ) : (
                            <p> Warning Status OK</p>
                        )}
                    </div>
                ) : (
                    <p>Profile information not available</p>
                )}
            </div>
        </div>
    );
};

export default MapSidebar;
