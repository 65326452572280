import { useEffect, useRef } from 'react';
import { useAuthContext } from '../context/useAuthContext';

export const useIP = () => {
    const { user } = useAuthContext();

    const sendHeartbeat = async (metadata) => {
        let userId = null;
        if (user && user.id) {
            userId = user.id;
        }
        try {
            const response = await fetch(`/ip`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ userId, metadata })
            });
            return await response.json();
        } catch (error) {
        }
    };

    return { sendHeartbeat };
};


export const useHeartbeat = () => {
    const { sendHeartbeat } = useIP();
    const heartbeatIntervalRef = useRef(null);
    const { metadata } = useAuthContext();

    useEffect(() => {
        const sendHeartbeatWithMetadata = async () => {
            await sendHeartbeat(metadata);
        };

        const startHeartbeat = () => {
            sendHeartbeatWithMetadata();
            heartbeatIntervalRef.current = setInterval(sendHeartbeatWithMetadata, 25000); 
        };

        const stopHeartbeat = () => {
            if (heartbeatIntervalRef.current) {
                clearInterval(heartbeatIntervalRef.current);
                heartbeatIntervalRef.current = null;
            }
        };

        const handleSessionControl = () => {
            const currentHeartbeatOwner = sessionStorage.getItem('heartbeatOwner');
            const currentTimestamp = String(Date.now());

            if (!currentHeartbeatOwner || currentTimestamp < currentHeartbeatOwner) {
                sessionStorage.setItem('heartbeatOwner', currentTimestamp);
                startHeartbeat();
            } else {
                stopHeartbeat();
            }
        };

        handleSessionControl();

        window.addEventListener('storage', handleSessionControl);

        return () => {
            window.removeEventListener('storage', handleSessionControl);
            stopHeartbeat();
            sessionStorage.removeItem('heartbeatOwner');
        };
    }, [sendHeartbeat]);
};