import React, { useState } from 'react';
import { useAuthContext } from '../context/useAuthContext';
import { useProfiles } from '../hooks/useProfiles';
import { useThreats } from '../hooks/useThreats';
import { useFiles } from '../hooks/useFiles';
import InputField from '../components/InputField';
import {
    InitialAssessmentForm,
    InvestigationForm,
    ActionForm,
    ReviewMonitorForm,
    ClosedForm
} from '../forms/LifecycleForms';

const lifecycleStates = ["Initial Assessment", "Investigation", "Action", "Review / Monitor", "Closed"];

function UpdateAudit({ threat }) {
    const { updateThreatFiles } = useThreats();
    const { user } = useAuthContext();
    const { updateProfileRecentActivity, updateProfileUnreadNotifications } = useProfiles();
    const { uploadFiles } = useFiles();
    const { updateThreat } = useThreats();

    const [files, setFiles] = useState([{ idx: 0, file: null }]);
    const [selectedState, setSelectedState] = useState('');
    const [actionType, setActionType] = useState('State Change');
    const [threatData, setThreatData] = useState({
        status: threat.status,
        updatedOn: Date.now(),
        username: user.username,
        description: '',
        files: {},
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [errorList, setErrorList] = useState([]);

    const handleFileChange = (e, idx) => {
        const newFiles = files.map((fileObj) =>
            fileObj.idx === idx ? { ...fileObj, file: e.target.files[0] } : fileObj
        );
        setFiles(newFiles);
    };

    const handleAddFile = () => {
        setFiles([...files, { idx: files.length ? files[files.length - 1].idx + 1 : 0, file: null }]);
    };

    const handleRemoveFile = (idx) => {
        setFiles(files.filter((fileObj) => fileObj.idx !== idx));
    };

    const handleStateChange = (e) => {
        setSelectedState(e.target.value);
        setThreatData({ ...threatData, "status": e.target.value });

    };

    const handleActionTypeChange = (e) => {
        setActionType(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        if (actionType === "File Upload") {
            const formData = new FormData();
            files.forEach((fileObj) => {
                if (fileObj.file) {
                    formData.append('files', fileObj.file);
                }
            });

            const filesData = await uploadFiles(formData);
            const filesIds = filesData.data.fileIds;
            let tempThreat = { ...threat };

            const json = await updateThreatFiles(tempThreat.id, filesIds);
            tempThreat = json.data.threat;
            tempThreat.history.push({
                status: threat.status,
                updatedOn: Date.now(),
                username: user.username,
                description: `${user.username} uploaded ${filesIds.length} files!`,
                files: filesIds
            });
            await updateThreat(tempThreat);
            updateProfileUnreadNotifications(user.profileRefId, {message: `Need to review new file for ${tempThreat.threatType} threat ${tempThreat.title}`, department: tempThreat.threatType, link: `/threats/${tempThreat.id}`});
            updateProfileRecentActivity(user.profileRefId, { type: 'uploadFiles', message: `Uploaded files to ${tempThreat.threatType} threat  ${tempThreat.title}`,  department: tempThreat.threatType, link: `/threats/${tempThreat.id}` });
        } else {
            let tempThreat = { ...threat };
            tempThreat.history.push(threatData);
            if (actionType === "State Change") {
                tempThreat.status = selectedState;
            }
            await updateThreat(tempThreat);
            updateProfileUnreadNotifications(user.profileRefId, {message: `Need to review new update for ${tempThreat.threatType} threat ${tempThreat.title}`,  department: tempThreat.threatType, link: `/threats/${tempThreat.id}`});
            updateProfileRecentActivity(user.profileRefId, { type: 'update', message: `Updated ${tempThreat.threatType} threat ${tempThreat.title}`,  department: tempThreat.threatType, link: `/threats/${tempThreat.id}` });
        }

        setFiles([{ idx: 0, file: null }]);
        setSelectedState('');
        setThreatData({
            status: threat.status,
            updatedOn: Date.now(),
            username: user.username,
            description: '',
            files: {},
        });
        setMessage('Update submitted successfully!');
    };

    const handleChange = (e) => {
        setThreatData({ ...threatData, [e.target.name]: e.target.value });
    };

    const renderForm = () => {
        if (actionType === "File Upload") {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <h2>Threat Upload</h2>
                    <label>{"Files accepted: .pdf, .doc, .docx, .jpg, .jpeg, .png"}</label>
                    <button type="button" style={{height: 20, width: 100}} onClick={handleAddFile}>
                        Add another file
                    </button>
                    {files.map((fileObj, idx) => (
                        <div key={idx}>
                            <input type="file" onChange={(e) => handleFileChange(e, fileObj.idx)} />
                            {idx > 0 && <button type="button" onClick={() => handleRemoveFile(fileObj.idx)}>X</button>}
                        </div>
                    ))}
                </div>
            );
        } else if (actionType === "State Change") {
            return (
                <>
                    <label htmlFor="lifecycleState">Lifecycle State:</label>
                    <select id="lifecycleState" name="lifecycleState" value={selectedState} onChange={handleStateChange}>
                        <option value="" disabled>Select threat state</option>
                        {lifecycleStates.map((state, idx) => (
                            <option key={idx} value={state}>
                                {state}
                            </option>
                        ))}
                    </select>
                    {selectedState && (
                        <>
                            {selectedState === "Initial Assessment" && <InitialAssessmentForm handleChange={handleChange} threat={threatData} error={error} errorList={errorList} user={user} />}
                            {selectedState === "Investigation" && <InvestigationForm handleChange={handleChange} threat={threatData} error={error} errorList={errorList} user={user} />}
                            {selectedState === "Action" && <ActionForm handleChange={handleChange} error={error} threat={threatData} errorList={errorList} user={user} />}
                            {selectedState === "Review / Monitor" && <ReviewMonitorForm handleChange={handleChange} threat={threatData} error={error} errorList={errorList} user={user} />}
                            {selectedState === "Closed" && <ClosedForm handleChange={handleChange} error={error} threat={threatData} errorList={errorList} user={user} />}
                        </>
                    )}
                </>
            );
        }
    };

    return (
        <div style={{ margin: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><h1 className="upload-heading">UPDATE THREAT</h1></div>

                <div style={{ height: 20 }}> </div>
                <select id="actionType" name="actionType" value={actionType} onChange={handleActionTypeChange}>
                    <option value="State Change">State Change</option>
                    <option value="File Upload">File Upload</option>
                </select>
                <div style={{margin: 25}}>
                    {renderForm()}
                </div>
                <button className="upload-button" onClick={(e) => handleSubmit(e)}>
                    {actionType === "File Upload" ? "Upload Files" : "Update Audit"}
                </button>
                {message && <div style={{ color: 'green' }}>{message}</div>}
            </div>
        </div>
    );
}

export default UpdateAudit;
