import React, { useState } from "react";
import Popup from '../components/Popup';
import moment from 'moment';
import { useThreats } from "../hooks/useThreats";
import { useAuthContext } from "../context/useAuthContext";

const Timeline = ({ threat, filesData }) => {
    const { user } = useAuthContext();
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const { updateThreat } = useThreats();
    const [newUpdate, setNewUpdate] = useState("");

    const handleFileClick = (file) => {
        const fileData = filesData[file];
        const fileExtension = fileData.fileExtension
        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
            setSelectedFile(`${fileData.path}`);
            setIsPopupVisible(true);
        } else if (['doc', 'docx', 'pdf'].includes(fileExtension)) {
            window.open(`${fileData.path}`, '_blank');
        }
    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        if (!newUpdate.trim()) return;

        const updatedThreat = {
            ...threat,
            history: [
                ...threat.history,
                {
                    status: threat.status,
                    updatedOn: new Date().toISOString(),
                    username: user.username,
                    description: newUpdate,
                    files: []
                }
            ]
        };

        try {
            await updateThreat(updatedThreat);
            setNewUpdate("");
        } catch (error) {
        }
    };

    const renderTimelineMarker = (date) => {
        return (
            <div style={timelineMarkerStyles}>
                {moment(date).format('MMMM Do YYYY, h:mm:ss a')}
            </div>
        );
    };

    function camelCaseToLabel(camelCaseString) {
        let label = camelCaseString.replace(/([A-Z])/g, ' $1');
        label = label.charAt(0).toUpperCase() + label.slice(1);
        return label;
    }

    return (
        <div style={{ width: "100%", height: "100%", margin: 20 }}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><h1 className="upload-heading">TIMELINE</h1></div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: 20 }}>
                {isPopupVisible && <Popup file={selectedFile} onClose={() => setIsPopupVisible(false)} />}
                <div style={{ marginTop: 50 }}>Original Incident occurred on: {renderTimelineMarker(threat.incidentTime)}</div>
                <div style={{ position: 'relative', border: "1px solid black", borderRadius: 10, margin: 20, padding: 10, width: '80%' }}>
                    <h3>Reporting Officer: {threat.username}</h3>
                    <div style={{ position: 'absolute', top: -20, left: 0, fontSize: 'small', color: 'gray' }}>
                        Created on {moment(threat.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                    </div>
                    <div>Description: A new threat has been reported!</div>
                </div>
                
                {threat.history.map((update, idx) => {
                    return (
                        <div key={idx} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "80%", margin: 20 }}>
                                <div style={{  top: -20, left: 0, fontSize: 'small', color: 'gray' }}>
                                    Updated on {moment(update.updatedOn).format('MMMM Do YYYY, h:mm:ss a')}
                                </div>
                            <div style={{ border: "1px solid black", borderRadius: 10, marginBottom: 20, padding: 10, width: '100%' }}>
                                <h4 style={{fontStyle: "italic"}}>Status: {update.status ? update.status : "Created"}</h4>
                                <h3>Updating Officer: {update.username}</h3>
                                {Object.entries(update).map(([key, value]) => (
                                    key !== 'updatedOn' && key !== 'username' && key !== 'files' && key!= "status"  && (
                                        <div key={key}>
                                            <strong>{camelCaseToLabel(key)}:</strong> {typeof value === 'object' && value !== null && !Array.isArray(value) ? JSON.stringify(value) : value}
                                        </div>
                                    )
                                ))}
                                {update.files && update.files.length > 0 && (
                                    <div>Uploaded Files: 
                                        <div style={{display: "flex"}}>
                                            {update.files.map((file, fileIdx) => {
                                                const fileData = filesData[file];
                                                const fileExtension = fileData ? fileData.fileType : '';
                                                let type = "https://icons.veryicon.com/png/o/file-type/system-icon/unknown-file-types.png";

                                                if (fileExtension === "doc") {
                                                    type = "https://cdn-icons-png.flaticon.com/512/3979/3979305.png";
                                                }
                                                if (fileExtension === "video") {
                                                    type = "https://cdn.iconscout.com/icon/free/png-512/free-mp4-file-26-521773.png?f=webp&w=512";
                                                }
                                                if (fileExtension === "audio") {
                                                    type = "https://cdn.iconscout.com/icon/free/png-512/free-music-file-8378475-6770049.png?f=webp&w=512";
                                                }
                                                if (fileExtension === "image") {
                                                    type = "https://cdn.iconscout.com/icon/free/png-512/free-gallery-7394876-6066632.png?f=webp&w=512";
                                                }
                                                return (
                                                    <div key={fileIdx} style={{display: 'flex', flexDirection: 'column', alignItems: "center", marginRight: 20}} onClick={() => handleFileClick(file)}>
                                                        <img style={{ width: 75, height: 100, cursor: 'pointer' }} src={type} alt={fileExtension} />
                                                        <label>{fileData ? fileData.filename : 'Unknown file'}</label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
                <form onSubmit={handleUpdateSubmit} style={{ marginTop: 20, width: '80%' }}>
                    <textarea
                        value={newUpdate}
                        onChange={(e) => setNewUpdate(e.target.value)}
                        placeholder="Add a new update..."
                        style={{ width: '100%', height: 100, padding: 10, borderRadius: 5, border: '1px solid lightgray' }}
                        disabled={threat.status === "Closed"} 
                    />
                    <button type="submit" disabled={threat.status === "Closed"} style={{ marginTop: 10, padding: '10px 20px', borderRadius: 5, border: 'none', background: '#007bff', color: 'white', cursor: 'pointer' }}>
                        Submit Update
                    </button>
                </form>
            </div>
        </div>
    );
};

const timelineMarkerStyles = {
    borderBottom: '1px solid lightgray',
};

export default Timeline;
