import React, { useState } from 'react';
import './CreateProfile.css';
import { useProfiles } from '../hooks/useProfiles';
import { useAccount } from '../hooks/useAccount';

const CreateProfile = () => {
    const { createProfile } = useProfiles()
    const { setError, error } = useAccount()
    const [message, setMessage] = useState()

    const [formData, setFormData] = useState({
        profileOnly: false,
        firstName: '',
        lastName: '',
        profilePicUrl: ["https://cdn-icons-png.flaticon.com/512/2922/2922510.png", "https://cdn-icons-png.flaticon.com/512/2922/2922516.png", "https://cdn-icons-png.flaticon.com/512/2922/2922522.png", "https://cdn-icons-png.flaticon.com/512/2922/2922561.png", "https://cdn-icons-png.flaticon.com/512/2922/2922566.png", "https://cdn-icons-png.flaticon.com/512/2922/2922583.png", "https://cdn-icons-png.flaticon.com/512/2922/2922587.png", "https://cdn-icons-png.flaticon.com/512/2922/2922592.png", "https://cdn-icons-png.flaticon.com/512/2922/2922596.png", "https://cdn-icons-png.flaticon.com/512/2922/2922606.png", "https://cdn-icons-png.flaticon.com/512/2922/2922610.png", "https://cdn-icons-png.flaticon.com/512/2922/2922616.png", "https://cdn-icons-png.flaticon.com/512/2922/2922624.png", "https://cdn-icons-png.flaticon.com/512/2922/2922632.png", "https://cdn-icons-png.flaticon.com/512/2922/2922640.png", "https://cdn-icons-png.flaticon.com/512/2922/2922644.png", "https://cdn-icons-png.flaticon.com/512/2922/2922648.png", "https://cdn-icons-png.flaticon.com/512/2922/2922654.png", "https://cdn-icons-png.flaticon.com/512/2922/2922660.png", "https://cdn-icons-png.flaticon.com/512/2922/2922666.png"][Math.floor(Math.random() * 20)],
        bio: 'New Member',
        birthDate: '',
        ssn: '12341234123',
        email: '',
        phoneNumber: '',
        groupName: '',
        userRole: '',
        threatSeverityRole: 'low',
    });

    const groupNames = [
        "Legal / Contractual",
        "Banking / Brokerage Account",
        "Accounting / Financial",
        "Customer Service Experience",
        "Stakeholder",
        "Travel Experience",
        "Expense Report",
        "Other",
        "Criminal Offense",
        "Termination Objection",
        "System Breach",
        "Defective Product / Process",
        // "Executives"
    ];

    const userRoles = [
        'admin',
        'user',
        'contactOnly'
    ];

    const threatSeverityRoles = [
        'low',
        'medium',
        'high'
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        setError("")
        setMessage("")
        const json = await createProfile(formData)
        if (json.error) {
        setError(json.message)
        } else {
            setMessage(json.message)
        }
        setFormData({
            profileOnly: false,
            firstName: '',
            lastName: '',
            profilePicUrl: ["https://cdn-icons-png.flaticon.com/512/2922/2922510.png", "https://cdn-icons-png.flaticon.com/512/2922/2922516.png", "https://cdn-icons-png.flaticon.com/512/2922/2922522.png", "https://cdn-icons-png.flaticon.com/512/2922/2922561.png", "https://cdn-icons-png.flaticon.com/512/2922/2922566.png", "https://cdn-icons-png.flaticon.com/512/2922/2922583.png", "https://cdn-icons-png.flaticon.com/512/2922/2922587.png", "https://cdn-icons-png.flaticon.com/512/2922/2922592.png", "https://cdn-icons-png.flaticon.com/512/2922/2922596.png", "https://cdn-icons-png.flaticon.com/512/2922/2922606.png", "https://cdn-icons-png.flaticon.com/512/2922/2922610.png", "https://cdn-icons-png.flaticon.com/512/2922/2922616.png", "https://cdn-icons-png.flaticon.com/512/2922/2922624.png", "https://cdn-icons-png.flaticon.com/512/2922/2922632.png", "https://cdn-icons-png.flaticon.com/512/2922/2922640.png", "https://cdn-icons-png.flaticon.com/512/2922/2922644.png", "https://cdn-icons-png.flaticon.com/512/2922/2922648.png", "https://cdn-icons-png.flaticon.com/512/2922/2922654.png", "https://cdn-icons-png.flaticon.com/512/2922/2922660.png", "https://cdn-icons-png.flaticon.com/512/2922/2922666.png"][Math.floor(Math.random() * 20)],
            bio: 'New Member',
            birthDate: '',
            ssn: '12341234123',
            email: '',
            phoneNumber: '',
            groupName: '',
            userRole: '',
            threatSeverityRole: 'low',
        })
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit} className="profile-form">
                <h2>New Profile Form</h2>
                <img style={{ borderRadius: 50, height: 100, width: 100 }} src={formData.profilePicUrl} alt="Profile Pic" />
                <div style={{display: "flex", }}>
                    <div className="form-group">
                        <label>First Name:
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>Last Name:
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>

                    <div className="form-group">
                    <label>Birth Date:
                        <input
                            type="date"
                            name="birthDate"
                            value={formData.birthDate}
                            onChange={handleChange}
                        />
                    </label>
                   </div>
                </div>
                <div className="form-group">
                    <label>Email:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </label>
                </div>

                <div className="form-group">
                    <label>Phone Number:
                        <input
                            type="tel"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label>Account Type:
                        <select
                            name="userRole"
                            value={formData.userRole}
                            onChange={handleChange}
                        >
                            <option value="">Select User Role</option>
                            {userRoles.map((role) => (
                                <option key={role} value={role}>{role}</option>
                            ))}
                        </select>
                    </label>
                </div>

                <div className="form-group">
                    <label>Department:
                        <select
                            name="groupName"
                            value={formData.groupName}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Alert Group</option>
                            {groupNames.map((name) => (
                                <option key={name} value={name}>{name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <button type="submit">Submit</button>
                {error && <div className="error">{error}</div>}
                {message && <div style={{color: "green"}}>{message}</div>}
            </form>
        </div>
    );
};

export default CreateProfile;
