import { useState } from "react"
import { useAuthContext } from "../context/useAuthContext"
import { useNavigate } from "react-router-dom"

export const useAccount = () => {
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(null) 
    const { user, dispatch } = useAuthContext()
    const navigate = useNavigate()


    const signup = async (userData, metaData) => {
        setIsLoading(true)
        setError(null)
        const response = await fetch('/authorize/signup', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({userData, metaData})
        })
        const json = await response.json()  
        if (!response.ok) {
            setError(json.message)
        }
        if (response.ok) {
            setError(json.message)
            if (!json.error) {
                dispatch({type: 'LOGIN', payload: json.data.user})
            }
        }
        setIsLoading(false)

        if (json.redirectTo){
            navigate(json.redirectTo)
        }

    }

    const login = async (userData, metaData) => {
        setIsLoading(true)
        setError(null) 
        const response = await fetch('/authorize/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({userData, metaData})
        })
        const json = await response.json()

        if (!response.ok) {
            setError(json.message)
        }
        if (response.ok) {
            setError(json.message)
            if (!json.error) {
                dispatch({type: 'LOGIN', payload: json.data.user})
                navigate(json.redirectTo)
            }
        }
        setIsLoading(false)

    }

    const logout = async() => {
        if (localStorage.getItem('user')){
            dispatch({type: "LOGOUT"})
            const response = await fetch(`/authorize/logout`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            })
        }             
        navigate('/')
        window.location.reload()
    }

    const checkLogout = () => {
        const currentTime = Date.now()/1000;
        if (!user) {
            logout()
            return
        } else if (currentTime >= user.exp || !user.authorized) {
            logout();
            return
        }     
    }
    
    const checkVerified = async (emailVerificationCode, MFACode, phoneVerificationCode) => {
        const response = await fetch('/authorize/verify', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({emailVerificationCode, MFACode, phoneVerificationCode})
        })
        return await response.json()
    }

    const checkAuthorized = async (MFACode) => {
        try {
            const response = await fetch('/authorize/check', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({MFACode})
            })
            return await response.json()            
        } catch (error) {
            
        }

    }

    const emailAuthorize = async (token) => {
        const response = await fetch(`/authorize/${token}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
        return await response.json()
    }

    const renewToken = async () => {
        const response = await fetch('/authorize/renew', {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            },
        })
        return await response.json()
    }

    const sendPanicAlert = async (panicType) => {
        const response = await fetch(`/authorize/alert`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({panicType})
        })
        return await response.json()    }

    
    const clearPanicAlert = async (panicType, userId) => {
        const response = await fetch(`/authorize/clear`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({panicType, userId})
        })
        return await response.json()    
    }

    return { login, logout, signup, checkVerified, error, setError, message, setMessage, isLoading, setIsLoading, checkAuthorized, emailAuthorize, renewToken, 
        sendPanicAlert,clearPanicAlert, checkLogout}
}