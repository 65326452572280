import { createContext, useState } from "react"
export const DashboardContext = createContext()

const DashboardContextProvider = (props) => {

    const [view, _setView] = useState("VIEW")
    const [subView, _setSubView] = useState("VIEW")
    const [threat, _setThreat ] = useState({})
    const [threats, _setThreats ] = useState({})
    const [profile, _setProfile ] = useState({})


    const setView = (view) => {
        _setView(view)
        _setSubView("VIEW")
    }

    const setSubView = (subView) => {
        _setSubView(subView)
    }

    const setThreat = (threat) => {
        _setThreat(threat)
    }

    const setThreats = (threats) => {
        _setThreats(threats)
    }

    const setProfile = (profile) => {
        _setProfile(profile)
    }


    return (
        <DashboardContext.Provider value={{
                view, setView, threat, setThreat, threats, setThreats, profile, setProfile, subView, setSubView
            }}>
            {props.children}
        </DashboardContext.Provider>
    )
}

export default DashboardContextProvider