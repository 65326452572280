export const useMetadata = () => {

    const getIpInfo = async () => {
        try {
            const response = await fetch(`/api/ipinfo`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                }
            })
            const json = await response.json()
            return json.data.ipData
        } catch (error) {
            return {}
        }
    }

    const getGeolocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve(position)
                    },
                    (error) => {
                        reject(error)
                    }
                )
            } else {
                reject(new Error('no geo support'))
            }
        })
    }

    const getDeviceInfo = () => {
        const userAgent = navigator.userAgent

        let browserName = 'Unknown'
        if (userAgent.indexOf('Firefox') > -1) {
            browserName = 'Mozilla Firefox'
        } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
            browserName = 'Opera'
        } else if (userAgent.indexOf('Trident') > -1) {
            browserName = 'Microsoft Internet Explorer'
        } else if (userAgent.indexOf('Edge') > -1) {
            browserName = 'Microsoft Edge'
        } else if (userAgent.indexOf('Chrome') > -1) {
            browserName = 'Google Chrome'
        } else if (userAgent.indexOf('Safari') > -1) {
            browserName = 'Apple Safari'
        }

        let osName = 'Unknown'
        if (userAgent.indexOf('Win') > -1) {
            osName = 'Windows'
        } else if (userAgent.indexOf('Mac') > -1) {
            osName = 'Macintosh'
        } else if (userAgent.indexOf('Linux') > -1) {
            osName = 'Linux'
        } else if (userAgent.indexOf('Android') > -1) {
            osName = 'Android'
        } else if (userAgent.indexOf('like Mac') > -1) {
            osName = 'iOS'
        }


        return {browser: browserName, os: osName, userAgent }
    }

    const getMetadata = async() => {
        const metadata = {deviceData: getDeviceInfo()}

        let ipData
        try {
            ipData = await getIpInfo()
            metadata["ipData"] = ipData
        } catch (error) {
        }

        let geoData
        try {
            geoData = await getGeolocation()
            const {coords, timestamp} = geoData
            const {latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed} = coords            
            metadata["geoData"] = {coords: { latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed }, timestamp}
        } catch (error) {
        }

        const location = {}
        if (geoData && geoData.coords) {
            location.lat =  geoData.coords.latitude
            location.lng =  geoData.coords.longitude
        } else if (ipData && ipData.loc) {
            const loc = ipData.loc.split(',')
            location.lat =  parseFloat(loc[0])
            location.lng =  parseFloat(loc[1])                
        } 
        metadata.lastLocation = location
        return metadata
    }

    return { getMetadata }
}
