import React, { useState, useEffect } from 'react';
import './LiveFeed.css'; 
import { useAuthContext } from '../context/useAuthContext';
import { useAccount } from '../hooks/useAccount';
import { useNavigate } from 'react-router-dom';
import { eventURL } from '../definitions';

const LiveFeed = () => {
    const [records, setRecords] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { user, dispatch } = useAuthContext();
    const { logout } = useAccount();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user || !user.authorized || (Date.now() / 1000 >= user.exp)) {
            logout();
            return;
        }
        if (user.role === "user") {
            navigate('/dashboard');
        }

        const url = new URL(`/records`, `${eventURL}`);

        url.searchParams.append('page', page);

        let eventSource = new EventSource(url);

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setRecords(data.data.records);
            setTotalPages(data.data.totalPages);
        };

        eventSource.onerror = (error) => {
            eventSource.close();
        };

        let userEventSource = new EventSource(`${eventURL}/users/exp/sse/${user.id}`);

        userEventSource.onmessage = (event) => {
            const userjson = JSON.parse(event.data);
            if (Date.now() / 1000 > userjson.refreshExp) {
                const newUser = { ...user, exp: userjson.refreshExp };
                dispatch({ type: "UPDATE_USER", payload: newUser });
            }
        };

        userEventSource.onerror = (error) => {
            userEventSource.close();
        };

        window.onbeforeunload = () => {
            eventSource.close();
            userEventSource.close();
        };

        return () => {
            eventSource.close();
            userEventSource.close();
        };
    }, [page, user]);

    if (!user) {
        return <div></div>;
    }

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const maskIpAddress = (ip) => {
        const parts = ip.split('.');
        if (parts.length === 4) {
            return `***.***.***.${parts[3]}`;
        }
        return ip; 
    };

    return (
        <div className="database-container">
            <h1 style={{ fontWeight: 'bold' }}>Live Feed</h1>
            <div className="table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Timestamp</th>
                            <th>Details</th>
                            <th>IP Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.length > 0 ? (
                            records.map(record => (
                                <tr key={record.id}>
                                    <td>{record.eventType}</td>
                                    <td>{new Date(record.createdAt).toLocaleString()}</td>
                                    <td>{JSON.stringify(record.details)}</td>
                                    <td>{maskIpAddress(record.ipAddress)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No records found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button disabled={page === 1} onClick={() => handlePageChange(page - 1)}>Previous</button>
                <span>Page {page} of {totalPages}</span>
                <button disabled={page === totalPages} onClick={() => handlePageChange(page + 1)}>Next</button>
            </div>
        </div>
    );
};

export default LiveFeed;
