import React, { useEffect, useState } from 'react';
import './Verify.css';
import { useAccount } from '../hooks/useAccount';
import Timer from '../components/Timer';
import { useAuthContext } from '../context/useAuthContext';
import { useNavigate } from 'react-router-dom';

const VerificationPage = () => {
    const [emailCode, setEmailCode] = useState('');
    const [phoneCode, setPhoneCode] = useState('');
    const [MFACode, setMFACode] = useState('');
    const { checkVerified, error, logout, setError} = useAccount();
    const { user, dispatch} = useAuthContext()
    const navigate = useNavigate()

    let expirationTime

    useEffect (()=>{
        if (!user || !user.login) {
        navigate('/')
        return

        } else{
        if(user && user.authorized) {
            const currentTime = Date.now()/1000;
            if (currentTime >= user.exp) {
                logout();
                return
            } else {
                navigate('/dashboard')
                return
            }
        } else {
            expirationTime = new Date(user.exp);
        }
        }
    }, [])

    const handleSubmit = async(e) => {
        e.preventDefault();
        if (emailCode) {
        const json = await checkVerified(emailCode, MFACode, phoneCode)

        setError(json.message)
        if (json.redirectTo) {
            if (json.redirectTo === '/authorization') {            
            dispatch({type: 'UPDATE_USER', payload: json.data.user})
            }
            navigate(json.redirectTo)
            alert(json.message)
        }

        } else {
        setError("Fill both codes")
        }
    };

    if (!user) {
        return <div></div>;
    }

    return (
        <div className="verification-container">
        <div className="card">
            <Timer expirationTime={user.exp} />
            <div className="verification-inputs">
            <div className="verification-item">
                <label>Email Code</label>
                <input
                type="text"
                placeholder="Enter Email Code"
                value={emailCode}
                onChange={(e) => setEmailCode(e.target.value)}
                />
            </div>
            <div className='verification-item'> 
                <p>Scan the QR code with your authenticator app:</p>
                <img src={user.qrCode} alt="MFA QR Code" />
            </div>
            <div className="verification-item">
                <label>MFA Code</label>
                <input
                type="text"
                placeholder="Enter MFA Code"
                value={MFACode}
                onChange={(e) => setMFACode(e.target.value)}
                />
            </div>
            <button onClick={handleSubmit}>Verify</button>
            {error && <div className="error">{error}</div>}

            </div>
        </div>
        </div>
    );
};

export default VerificationPage;
