import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api';
import { useApi } from '../hooks/useApi';
import CustomMarker from '../components/CustomMarker';
import MapSidebar from '../components/MapSidebar';
import DraggableTable from '../components/DraggableTable';
import { useAccount } from '../hooks/useAccount';
import { useAuthContext } from '../context/useAuthContext';
import { useNavigate } from 'react-router-dom';
import { getDistance } from 'geolib';
import { eventURL } from '../definitions';

const Map = () => {
    const { getMapAuth, sendMapAlert } = useApi();
    const [users, setUsers] = useState([]);
    const [mapKey, setMapKey] = useState('');
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [mapHeight, setMapHeight] = useState('calc(100vh - 10vh)');
    const [radius, setRadius] = useState(0);
    const { user, dispatch } = useAuthContext();
    const { logout } = useAccount();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user || !user.authorized) {
            logout();
            return;
        }
        const currentTime = Date.now() / 1000;
        if (currentTime >= user.exp) {
            logout();
            return;
        }

        if (user.role === "user") {
            navigate('/dashboard');
            return;
        }

        const fetchMap = async () => {
            const json = await getMapAuth();
            if (json) {
                setMapKey(json.data.key);
            }
        };

        fetchMap();
    }, [user]);

    useEffect(() => {
        if (user) {
            const eventSource = new EventSource(`${eventURL}/users/map`);
            const userEventSource = new EventSource(`${eventURL}/users/exp/sse/${user.id}`);

            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setUsers(data.data.users);
            };

            eventSource.onerror = (error) => {
                console.error('EventSource failed:', error);
                eventSource.close();
            };

            userEventSource.onmessage = (event) => {
                const userjson = JSON.parse(event.data);
                if (Date.now() / 1000 > userjson.refreshExp) {
                    const newUser = { ...user, exp: userjson.refreshExp };
                    dispatch({ type: "UPDATE_USER", payload: newUser });
                }
            };

            userEventSource.onerror = (error) => {
                userEventSource.close();
            };

            window.onbeforeunload = () => {
                eventSource.close();
                userEventSource.close();
            };

            return () => {
                eventSource.close();
                userEventSource.close();
            };
        }
    }, [user, setUsers]);

    const handleMarkerClick = (user) => {
        setRadius(0);
        setSelectedMarker(user);
        setSelectedProfile(user.profile);
        setIsSidebarOpen(true);
        setCenter(user.lastLocation);
    };

    const handleCloseSidebar = () => {
        setIsSidebarOpen(false);
    };

    const handleDrag = (height) => {
        setMapHeight(`calc(100vh - 10vh - ${height})`);
    };

    const handleRadiusChange = (num) => {
        setRadius(num);
    };

    const handleSendAlert = () => {
        if (!selectedMarker) return;

        const nearbyUsers = users.filter(user => {
            if (!user.lastLocation || user.id === selectedMarker.id) return false;

            const distance = getDistance(
                { latitude: selectedMarker.lastLocation.lat, longitude: selectedMarker.lastLocation.lng },
                { latitude: user.lastLocation.lat, longitude: user.lastLocation.lng }
            );

            return distance <= radius;
        });

        let alertMessage = 'Nearby users within radius:\n';
        nearbyUsers.forEach(user => {
            alertMessage += `${user.profile.firstName} ${user.profile.lastName} : ${user.email}\n`;
            sendMapAlert(user.profile.id);
        });
        alert(alertMessage);
    };

    if (!user || !user.authorized || (Date.now() / 1000 >= user.exp)) {
        return <div></div>;
    }

    const onlineUsers = users.filter(user => Date.now() - user.lastTimestamp <= 30 * 1000);
    const offlineUsers = users.filter(user => Date.now() - user.lastTimestamp > 30 * 1000);

    const handleZoomToUser = (user) => {
        setSelectedMarker(user);
        setSelectedProfile(user.profile);
        setCenter(user.lastLocation);
        setIsSidebarOpen(true);
    };

    return (
        mapKey ? (
            <div style={{ position: 'relative', height: '90dvh' }}>
                <h1 style={{fontWeight: 'bold'}}>Global Agent Tracker</h1>
                <div style={{ height: "10dvh", display: 'flex', flexDirection: "column" }}>
                    <div style={{ display: 'flex' }}>
                        <h2>Online - Users: {onlineUsers.length}</h2>
                        <h2 style={{ marginLeft: 20 }}>Watches: {onlineUsers.filter(user => user.watch).length}</h2>
                        <h2 style={{ marginLeft: 20 }}>Warnings: {onlineUsers.filter(user => user.warning).length}</h2>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <h2>Offline - Users: {offlineUsers.length}</h2>
                        <h2 style={{ marginLeft: 20 }}>Watches: {offlineUsers.filter(user => user.watch).length}</h2>
                        <h2 style={{ marginLeft: 20 }}>Warnings: {offlineUsers.filter(user => user.warning).length}</h2>
                    </div>
                </div>
                <LoadScript googleMapsApiKey={mapKey+"&loading=async"}>
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: mapHeight }}
                        center={center}
                        zoom={2}
                        options={{ maxZoom: 20, minZoom: 1, restriction: {
                            latLngBounds: {
                                north: 85,
                                south: -88,
                                east: 179,
                                west: -179,
                            },
                            strictBounds: true,
                        } }}
                    >
                        {offlineUsers.map(user => (
                            <CustomMarker
                                key={user.id}
                                position={user.lastLocation}
                                content={user}
                                onClick={() => handleMarkerClick(user)}
                                status={'offline'}
                                role={user.profile.userRole}
                                state={user.profile.groupName}
                            />
                        ))}
                        {onlineUsers.map(user => (
                            <CustomMarker
                                key={user.id}
                                position={user.lastLocation}
                                content={user}
                                onClick={() => handleMarkerClick(user)}
                                status={'online'}
                                role={user.profile.userRole}
                                state={user.profile.groupName}
                            />
                        ))}
                        {selectedMarker && (selectedMarker.watchThreatRefId || selectedMarker.warningThreatRefId) && (
                            <Circle
                                center={selectedMarker.lastLocation}
                                radius={radius}
                                options={{ fillColor: 'rgba(0,0,255,0.2)', strokeColor: 'blue' }}
                            />
                        )}
                    </GoogleMap>
                </LoadScript>
                {isSidebarOpen && selectedMarker && (
                    <MapSidebar
                        isOpen={isSidebarOpen}
                        content={selectedMarker}
                        profile={selectedProfile}
                        onClose={handleCloseSidebar}
                        handleRadiusChange={handleRadiusChange}
                        radius={radius}
                        handleSendAlert={handleSendAlert}
                    />
                )}
                <DraggableTable
                    onDrag={handleDrag}
                    onlineUsers={onlineUsers}
                    offlineUsers={offlineUsers}
                    onRowClick={handleZoomToUser}
                />
            </div>
        ) : (
            <div>No map loaded</div>
        )
    );
};

export default Map;
