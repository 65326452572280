import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tile } from '../components/Tile';
import { useProfiles } from '../hooks/useProfiles';
import { CreateReport } from '../dashComponents/CreateReport';
import { useDashContext } from '../context/useDashContext';
import './ViewThreats.css';
import { useAccount } from '../hooks/useAccount';
import { useAuthContext } from '../context/useAuthContext';
import { eventURL } from '../definitions';

export const ViewThreats = () => {
    const { threats, setThreats } = useDashContext();
    const { getProfile } = useProfiles();
    const navigate = useNavigate();
    const { user, dispatch } = useAuthContext();
    const { logout } = useAccount();
    const [profile, setProfile] = useState(null);
    const [activeTab, setActiveTab] = useState('openAudits');
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalThreats, setTotalThreats] = useState(0);
    const threatsPerPage = 15;
    const intervalIdRef = useRef(null);

    useEffect(() => {
        if (!user || !user.authorized) {
            logout();
            return;
        }
        const currentTime = Date.now() / 1000;
        if (currentTime >= user.exp) {
            logout();
            return;
        }

        const fetchProfile = async () => {
            try {
                const profileData = await getProfile();
                setProfile(profileData.data.profile);
            } catch (error) {
                console.error('Failed to fetch profile:', error);
            }
        };

        fetchProfile();

        const eventSource = new EventSource(`${eventURL}/threats/?profileId=${user.profileRefId}&page=${page}&limit=${threatsPerPage}`);
        const userEventSource = new EventSource(`${eventURL}/users/exp/sse/${user.id}`);

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setThreats(data.data.threats);
            setTotalThreats(data.data.totalThreats);
            setLoading(false);
        };

        eventSource.onerror = (error) => {
            console.error('EventSource failed:', error);
            eventSource.close();
        };

        userEventSource.onmessage = (event) => {
            const userjson = JSON.parse(event.data);
            if (Date.now() / 1000 > userjson.refreshExp) {
                const newUser = { ...user, exp: userjson.refreshExp };
                dispatch({ type: "UPDATE_USER", payload: newUser });
            }
        };

        userEventSource.onerror = (error) => {
            userEventSource.close();
        };

        window.onbeforeunload = () => {
            eventSource.close();
            userEventSource.close();
        }

        return () => {
            eventSource.close();
            userEventSource.close();
        };
    }, [user, page]);

    if (!user || !user.authorized || (Date.now() / 1000 >= user.exp)) {
        return <div></div>;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!profile) {
        return <div>Loading profile...</div>;
    }

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const totalPages = Math.ceil(totalThreats / threatsPerPage);

    return (
        <div className="view-threats">
            <div style={{display: 'flex'}}>
                {activeTab === "closeAudits" && <h1 style={{fontWeight: 'bold'}}>Report Threat</h1>}
                {activeTab === "openAudits" && <h1 style={{fontWeight: 'bold'}}>All Threats</h1>}
                {activeTab === "closeAudits" && <button onClick={() => handleTabClick('openAudits')}>
                    View Threats
                </button>}
                {activeTab === "openAudits" && <button onClick={() => handleTabClick('closeAudits')}>
                    Report Threat
                </button>}
            </div>

            <div>
                <div className='tab-content'>
                    <div className={`tab-pane fade ${activeTab === 'openAudits' ? 'show active' : ''}`}>
                        <div className='table-responsive'>
                            <table className='table audits-table'>
                                <thead>
                                    <tr>
                                        <th style={{width: "5%"}}>Severity</th>
                                        <th style={{width: "10%"}}>Status</th>
                                        <th style={{width: "10%"}}>Created At</th>
                                        <th style={{width: "10%"}}>Department</th>
                                        <th style={{width: "10%"}}>Agent</th>
                                        <th style={{width: "15%"}}>Title</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {threats.length > 0 && threats.map((threat, idx) => (
                                        <Tile key={threat.id} threat={threat} />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='pagination'>
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index + 1}
                                    onClick={() => handlePageChange(index + 1)}
                                    disabled={page === index + 1}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className={`tab-pane fade ${activeTab === 'closeAudits' ? 'show active' : ''}`}>
                        <CreateReport />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewThreats;
