import React, { useState, useRef, useEffect } from 'react';
import './DraggableTable.css';

const DraggableTable = ({ onDrag, onlineUsers, offlineUsers, onRowClick }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [startY, setStartY] = useState(0);
    const [startHeight, setStartHeight] = useState(0);
    const tableRef = useRef(null);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartY(e.clientY);
        setStartHeight(tableRef.current.offsetHeight);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const newHeight = startHeight + (startY - e.clientY);
        tableRef.current.style.height = `${newHeight}px`;
        onDrag(`${newHeight}px`);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        if (isDragging) {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        } else {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const formatTimestamp = (timestamp) => {
        const date = new Date(Number(timestamp));
        return date.toLocaleString();
    };

    return (
        <div ref={tableRef} className="map-draggable-table" style={{ height: '10vh', transform: "translate(0px,20px)" }}>
        <div className="map-drag-handle" onMouseDown={handleMouseDown}></div>
        <div className="map-table-content">
            <table className="map-table">
            <thead>
                <tr>
                <th>Status</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Last Location</th>
                <th>Last Active</th>
                </tr>
            </thead>
            <tbody>
                {onlineUsers.map((user, idx) => (
                <tr key={idx} onClick={() => onRowClick(user)}>
                    <td>
                    <span className="status-dot online"></span>
                    <span className={`status-dot ${user.watch ? 'watch' : 'offline'}`}></span>
                    <span className={`status-dot ${user.warning ? 'warning' : 'offline'}`}></span>
                    </td>
                    <td>{user.profile.firstName} {user.profile.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.phoneNumber}</td>
                    <td>{user.lastLocation ? `${user.lastLocation.lat}, ${user.lastLocation.lng}` : 'N/A'}</td>
                    <td>{formatTimestamp(user.lastTimestamp)}</td>
                </tr>
                ))}
                {offlineUsers.map((user, idx) => (
                <tr key={idx} onClick={() => onRowClick(user)}>
                    <td>
                    <span className="status-dot offline"></span>
                    <span className={`status-dot ${user.watch ? 'watch' : 'offline'}`}></span>
                    <span className={`status-dot ${user.warning ? 'warning' : 'offline'}`}></span>
                    </td>
                    <td>{user.profile.firstName} {user.profile.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.phoneNumber}</td>
                    <td>{user.lastLocation ? `${user.lastLocation.lat}, ${user.lastLocation.lng}` : 'N/A'}</td>
                    <td>{formatTimestamp(user.lastTimestamp)}</td>
                </tr>
                ))}
            </tbody>
            </table>
        </div>
        </div>
    );
};

export default DraggableTable;
