import { useEffect, useRef } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import './CustomMarker.css';

const CustomMarker = ({ position, content, onClick, status, role, state }) => {
    const map = useGoogleMap();
    const markerRef = useRef(null);
    const markerDivRef = useRef(null);

    useEffect(() => {
        if (map && !markerRef.current) {
            const markerDiv = document.createElement('div');
            markerDiv.className = `map-custom-marker ${status} ${role} ${state}`;
            markerDiv.innerHTML = content.profile.firstName;
            markerDivRef.current = markerDiv;

            markerDiv.addEventListener('click', () => {
                onClick(position, content);
                map.panTo(new window.google.maps.LatLng(position.lat, position.lng));
                map.setZoom(20);
            });

            markerRef.current = new window.google.maps.OverlayView();
            markerRef.current.onAdd = () => {
                const panes = markerRef.current.getPanes();
                panes.overlayMouseTarget.appendChild(markerDiv);
            };
            markerRef.current.draw = () => {
                const overlayProjection = markerRef.current.getProjection();
                const point = overlayProjection.fromLatLngToDivPixel(new window.google.maps.LatLng(position.lat, position.lng));
                if (point) {
                    markerDiv.style.left = `${point.x}px`;
                    markerDiv.style.top = `${point.y}px`;
                }
            };
            markerRef.current.onRemove = () => {
                if (markerDiv.parentElement) {
                    markerDiv.parentElement.removeChild(markerDiv);
                }
                markerRef.current = null;
            };

            markerRef.current.setMap(map);
        }
    }, [map, position, content, onClick, status, role, state]);

    return null;
};

export default CustomMarker;
