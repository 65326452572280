import { useAccount } from './useAccount'


export const useForms = () => {
        const { checkLogout} = useAccount()
    
    const getAllForms = async() => {
        checkLogout()
        const response = await fetch(`/forms`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        return await response.json()

    }
    return {getAllForms}
}





