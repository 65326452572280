import "./InputField.css"

const InputField = ({ disabled, label, text, name, onChange, defaultValue, error, errorList }) => {
    return(
        <div style={{display: 'flex', width: "60dvw"}}>                
            <label className="account-label">{text}<span>{error ? " " : ""}</span>
            </label>
            {disabled ? 
            <input
                className="account-input"
                type={label}
                name={name}
                onChange={onChange}
                readOnly
                value={defaultValue ? defaultValue : ""}
                style={ errorList.includes(name) ? {borderColor: 'red'} : null}
            /> :
            <input
            className="account-input"
            type={label}
            name={name}
            onChange={onChange}
            value={defaultValue ? defaultValue : ""}
            style={ errorList.includes(name) ? {borderColor: 'red'} : null}
        />}

        </div>
    )
}

export default InputField