import React from 'react';
import InputField from '../components/InputField';

export const InitialAssessmentForm = ({ handleChange, threat, error, errorList, user }) => (
    <div>
        <InputField
            label={'text'}
            text="Date Reported:"
            disabled={true}
            name="updatedOn"
            defaultValue={new Date()}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'text'}
            text="Reporting Officer:"
            disabled={true}
            name="otherAuthor"
            defaultValue={user.username}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'text'}
            text="Action Taken:"
            name="description"
            defaultValue={threat.description}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'text'}
            text="Initial Assessment Details:"
            name="initialAssessmentDetails"
            defaultValue={threat.initialAssessmentDetails}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'textarea'}
            text="Preliminary Findings:"
            name="preliminaryFindings"
            defaultValue={threat.preliminaryFindings}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
    </div>
);

export const InvestigationForm = ({ handleChange, threat, error, errorList, user }) => (
    <div>
        <InputField
            label={'text'}
            text="Date Reported:"
            disabled={true}
            name="updatedOn"
            defaultValue={new Date()}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'text'}
            text="Reporting Officer:"
            disabled={true}
            name="otherAuthor"
            defaultValue={user.username}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'text'}
            text="Action Taken:"
            name="description"
            defaultValue={threat.description}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'text'}
            text="Investigation Details:"
            name="investigationDetails"
            defaultValue={threat.investigationDetails}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
    </div>
);

export const ActionForm = ({ handleChange, threat, error, errorList, user }) => (
    <div>
        <InputField
            label={'text'}
            text="Date Reported:"
            disabled={true}
            name="updatedOn"
            defaultValue={new Date()}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'text'}
            text="Reporting Officer:"
            disabled={true}
            name="otherAuthor"
            defaultValue={user.username}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'text'}
            text="Action Taken:"
            name="description"
            defaultValue={threat.description}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'textarea'}
            text="Action Description:"
            name="actionDescription"
            defaultValue={threat.actionDescription}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'date'}
            text="Date of Action:"
            name="dateOfAction"
            defaultValue={threat.dateOfAction}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
    </div>
);

export const ReviewMonitorForm = ({ handleChange, threat, error, errorList, user }) => (
    <div>
        <InputField
            label={'text'}
            text="Date Reported:"
            disabled={true}
            name="updatedOn"
            defaultValue={new Date()}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'text'}
            text="Reporting Officer:"
            disabled={true}
            name="otherAuthor"
            defaultValue={user.username}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'text'}
            text="Review Details:"
            name="reviewDetails"
            defaultValue={threat.reviewDetails}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'textarea'}
            text="Monitoring Plan:"
            name="monitoringPlan"
            defaultValue={threat.monitoringPlan}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
    </div>
);

export const ClosedForm = ({ handleChange, threat, error, errorList, user }) => (
    <div>
        <InputField
            label={'text'}
            text="Date Reported:"
            disabled={true}
            name="updatedOn"
            defaultValue={new Date()}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
        <InputField
            label={'text'}
            text="Closure Details:"
            name="closureDetails"
            defaultValue={threat.closureDetails}
            onChange={handleChange}
            error={error}
            errorList={errorList}
        />
    </div>
);
