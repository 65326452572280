import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Timeline from "../dashComponents/Timeline";
import UpdateAudit from "../dashComponents/UpdateAudit";
import FileReview from "../components/FileReview";
import GenerateReport from "../components/GenerateReport";
import { useThreats } from "../hooks/useThreats";
import { useAuthContext } from "../context/useAuthContext";
import { useAccount } from "../hooks/useAccount";
import moment from 'moment';
import { eventURL } from "../definitions";

const lifecycleStates = ["Created", "Initial Assessment", "Investigation", "Action", "Review / Monitor", "Closed"];

export const ThreatManager = () => {
    const { id } = useParams();
    const [threat, setThreat] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); 
    const [tempView, setTempView] = useState("TIMELINE");
    const [filesData, setFilesData] = useState({});
    const { getThreatById, updateThreat } = useThreats();
    const navigate = useNavigate();
    const { user, dispatch } = useAuthContext();
    const { logout } = useAccount();
    const map = { "Low": "yellow", "Medium": "orange", "High": "red", "low": "yellow", "medium": "orange", "high": "red" };

    useEffect(() => {
        if (!user || !user.authorized) {
            logout();
            return;
        }
        const currentTime = Date.now() / 1000;
        if (currentTime >= user.exp) {
            logout();
            return;
        }

        let eventSource;
        let userEventSource;

        eventSource = new EventSource(`${eventURL}/threats/${id}`);

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.error) {
                setError(data.message);
                navigate("/viewthreats");
                return;
            }
            setThreat(data.data.threat);
            setFilesData(data.data.files);
            if (data.data.threat.status === "Closed") {
                setTempView("TIMELINE");
            }
            setLoading(false);
        };

        eventSource.onerror = (error) => {
            console.error('EventSource failed:', error);
            eventSource.close();
        };

        userEventSource = new EventSource(`${eventURL}/users/exp/sse/${user.id}`);


        userEventSource.onmessage = (event) => {
            const userjson = JSON.parse(event.data);
            if (Date.now() / 1000 > userjson.refreshExp) {
                const newUser = { ...user, exp: userjson.refreshExp };
                dispatch({ type: "UPDATE_USER", payload: newUser });
            }
        };

        userEventSource.onerror = (error) => {
            userEventSource.close();
        };

        window.onbeforeunload = () => {
            eventSource.close();
            userEventSource.close();
        }

        return () => {
            eventSource.close();
            userEventSource.close();
        };
    }, [user]);

    if (!user || !user.authorized || (Date.now() / 1000 >= user.exp)) {
        return <div></div>;
    }

    if (loading) {
        return <div>Loading Threat...</div>;
    }

    if (error) {
        return (
            <div>
                <p>{error}</p>
                <button onClick={() => navigate("/viewthreats")}>{"<- Back to All Threats"}</button>
            </div>
        );
    }

    const reopenThreat = async () => {
        threat.status = "Review / Monitor";
        threat.history.push({ "status": threat.status, "files": {}, "username": user.username, "updatedOn": Date.now(), "description": "Threat Reopened" });
        await updateThreat(threat);
    };

    function formatKey(key) {
        return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    }

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    };

    const renderTimelineMarker = (date) => {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    };

    return (
        <div style={{ display: "flex", minHeight: "95dvh", flexDirection: "column", width: "100%", height: "100%", backgroundColor: "#eee", alignItems: "center", overflowY: "auto" }}>
            <div style={{ display: 'flex' }}>
                <button onClick={() => navigate("/viewthreats")}>{"View All Threats"}</button>
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: "95%", backgroundColor: "white", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: 15 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 20 }}>
                    {lifecycleStates.map((state, index) => (
                        <div key={index} style={{ display: 'flex', fontSize: '18px', margin: '0 10px', height: 50, width: 150, justifyContent: 'center', alignItems: 'center', borderRadius: 5, backgroundColor: threat.status === state ? 'green' : 'gray', color: 'white' }}>
                            <label>{state}</label>
                        </div>
                    ))}
                </div>
                <div style={{ position: 'relative', border: "1px solid white", borderRadius: 10, margin: 20, padding: 10, width: '90%' }}>
                    <div style={{ display: 'flex', alignItems: "flex-end", marginBottom: "20px" }}>
                        <h1 style={{ fontWeight: "bold", fontSize: '3dvh' }}>{threat.title}</h1>
                        <h2 style={{ marginLeft: '20px', fontStyle: "italic" }}>Reported By {threat.username} on {renderTimelineMarker(threat.createdAt)}</h2>
                        <h2 style={{ marginLeft: "20px", backgroundColor: map[threat.severity] }}>{threat.severity}</h2>
                    </div>
                    <h2 style={{ textIndent: '2em' }}>{threat.description}</h2>
                    <div style={{ color: "white", fontSize: 20 }}>.</div>
                    {threat.threatSpecificData && !isEmptyObject(threat.threatSpecificData) && <h1 style={{ fontWeight: "bold" }}>Relevant Information:</h1>}
                    {threat.threatSpecificData && !isEmptyObject(threat.threatSpecificData) && Object.entries(threat.threatSpecificData).map(([key, value]) => (
                        <div key={key} style={{ display: 'flex', marginBottom: 10 }}>
                            <h2 style={{ marginRight: 5 }}>{formatKey(key)}:</h2>
                            <h2>{value}</h2>
                        </div>
                    ))}
                </div>

                <GenerateReport threat={threat} />
            </div>
            <div style={{ height: 20 }}></div>
            <div style={{ display: 'flex' }}>
                <button style={{ height: "50px", width: "100px" }} disabled={threat.status === "Closed"} onClick={() => { setTempView("TIMELINE") }}>Timeline</button>
                <button style={{ height: "50px", width: "100px" }} disabled={threat.status === "Closed"} onClick={() => { setTempView("UPDATE") }}>Update Threat</button>
                <button style={{ height: "50px", width: "100px" }} disabled={threat.status === "Closed"} onClick={() => { setTempView("REVIEW") }}>File Review</button>
                {user.role === 'admin' && <button style={{ height: "50px", width: "100px" }} disabled={threat.status !== "Closed"} onClick={() => { reopenThreat() }}>Reopen Threat</button>}
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: "95%", backgroundColor: "white", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: 15 }}>
                {tempView === "TIMELINE" && <Timeline filesData={filesData} threat={threat} threatId={id} />}
                {tempView === "UPDATE" && <UpdateAudit threat={threat} />}
                {tempView === "REVIEW" && <FileReview threat={threat} filesData={filesData} />}
            </div>
        </div>
    );
};

export default ThreatManager;
