import { useEffect, useState } from "react"
import { useAuthContext } from "../context/useAuthContext"
import { useProfiles } from "../hooks/useProfiles"
import { useNavigate } from "react-router-dom"
import { useAccount } from "../hooks/useAccount"
import { eventURL } from "../definitions"

const Home = () => {
    const navigate = useNavigate()
    const { logout } = useAccount()
    const { user, dispatch } = useAuthContext()
    const { getProfile } = useProfiles()
    const [profile, setProfile] = useState(null)


    useEffect(() => {
        if (!user || !user.authorized) {
            logout();
            return;
          }
        if (user) {
            const currentTime = Date.now()/1000;
            if (currentTime >= user.exp) {
                logout();
                return
            } 
        }
        let eventSource

        eventSource = new EventSource(`${eventURL}/users/exp/sse/${user.id}`);

        eventSource.onmessage = (event) => {
            const userjson = JSON.parse(event.data);
            if (Date.now() / 1000 > userjson.refreshExp) {
                const newUser = { ...user, exp: userjson.refreshExp };
                dispatch({ type: "UPDATE_USER", payload: newUser });
            }
        };

        eventSource.onerror = (error) => {
            eventSource.close();
        };

        window.onbeforeunload = () => {
            eventSource.close()
        }


        const fetchProfile = async () => {
            try {
                const newProfile = await getProfile()
                if (newProfile.data.profile) {
                    const sortedRecentActivity = newProfile.data.profile.recentActivity.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                    setProfile({
                        ...newProfile.data.profile,
                        recentActivity: sortedRecentActivity.map(activity => ({
                            ...activity,
                            formattedTimestamp: new Date(activity.timestamp).toLocaleString()
                        }))
                    })
                }
            } catch (error) {
            }
        }
        fetchProfile()

        return () => {
            eventSource.close();
        };            
    }, [user])

    if (!user || !user.authorized || (Date.now() / 1000 >= user.exp)) {
        return <div></div>;
    }

    if (!profile) {
        return <div>Loading...</div>
    }

    return (
        <div  className="database-container">
            <h1 style={{ marginBottom: 20, fontWeight: "bold" }}>Welcome back {profile.firstName + " " + profile.lastName}!</h1>
            <div style={{display: "flex", width: "80dvw"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img style={{height: 100, width: 350}} src="/assets/images/seethreat.jpg" alt="logo"></img>
                    <h2>
                    Risk Management, Risk Monitoring, and Risk Mitigation are key cornerstones of business and asset protection This platform and its team are devoted to helping to mitigate risks that are central to enterprises and BRANDS, which are managed and/or affiliated with the willlouden.com, Inc. enterprise. </h2>
                </div> 
                <video width="640" height="360" controls>
                    <source src="/assets/seethreat.mp4" type="video/mp4"/>
                </video>               
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2 style={{fontWeight: "bold", marginTop: 20}}>Notifications</h2>
                    <div style={{ border: "1px solid black", display: "flex", flexDirection: "column", width: "80vw", height: "15vh", marginBottom: 20, overflowY: "auto" }}>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                                <tr>
                                    <th style={{ border: "1px solid white", padding: "8px" }}>Activity</th>
                                    <th style={{ border: "1px solid white", padding: "8px" }}>Link</th>
                                </tr>
                            </thead>
                            <tbody>
                            {profile && profile.unreadNotifications && profile.unreadNotifications.slice().reverse().map((notification, idx) => {
                                    return <tr key={idx} style={{ backgroundColor: idx % 2 === 0 ? "#f2f2f2" : "#ffffff" }}>
                                        <td style={{ border: "1px solid white", padding: "8px" }}>{notification.message}</td>
                                        {notification.link && <td style={{ border: "1px solid white", color: 'blue', padding: "8px" }} onClick={()=>{if (notification.link) navigate(notification.link.replace('/threats/', '/threat/'))}} >{notification.link.includes("threat") ? "View Threat" : "View Profile"}</td>}
                                    </tr>
                            })}
                            </tbody>
                    </table>
                    </div>
                    <h2 style={{fontWeight: "bold"}}>Recent Activity</h2>
                    <div style={{ border: "1px solid black", display: "flex", flexDirection: "column", width: "80vw", height: "20vh", overflowY: "auto", marginBottom: 20 }}>
                        <table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                                <tr>
                                    <th style={{ border: "1px solid white", padding: "8px", width: "20%" }}>Timestamp</th>
                                    <th style={{ border: "1px solid white", padding: "8px" }}>Activity</th>
                                    <th style={{ border: "1px solid white", padding: "8px" }}>Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                {profile.recentActivity.map((activity, idx) => {                            
                                    return <tr key={idx} style={{ backgroundColor: idx % 2 === 0 ? "#f2f2f2" : "#ffffff" }}>
                                        <td style={{ border: "1px solid white", padding: "8px" }}>{activity.formattedTimestamp}</td>
                                        <td style={{ border: "1px solid white", padding: "8px" }}>{activity.message}</td>
                                        {activity.link && <td style={{ border: "1px solid white", color: 'blue', padding: "8px" }} onClick={()=>{if (activity.link) navigate(activity.link.replace('/threats/', '/threat/'))}} >{activity.link.includes("threat") ? "View Threat" : "View Profile"}</td>}
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
