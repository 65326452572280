import React, { useEffect, useState } from 'react';
import AllFiles from '../dashComponents/AllFiles';
import DatabaseUpload from '../dashComponents/DatabaseUpload';
import { useAccount } from '../hooks/useAccount';
import { useAuthContext } from '../context/useAuthContext';

const Database = () => {
    const [view, setView] = useState('all');
    const { user} = useAuthContext()
    const { logout} = useAccount()

    useEffect(() => {
        if (!user || !user.authorized) {
            logout();
            return;
        }
        const currentTime = Date.now()/1000;
        if (currentTime >= user.exp) {
            logout();
            return
        } 
    })

    if (!user || !user.authorized || (Date.now() / 1000 >= user.exp)) {
        return <div></div>;
    }
    return (
        <div className="database-container">
            <div style={{display: 'flex'}}>
                {view === "all" && <h1 style={{fontWeight: 'bold'}}>File Database</h1>}
                {view === "database" && <h1 style={{fontWeight: 'bold'}}>Upload Files</h1>}
                {view === "database" && <button onClick={() => setView('all')}>All Files</button>}
                {view === "all" && <button onClick={() => setView('database')}>Database Upload</button>}
            </div>

            <div className="database-content">
                {view === 'all' && <AllFiles />}
                {view === 'database' && <DatabaseUpload />}
            </div>
        </div>
    );
};

export default Database;
