import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAccount } from "../hooks/useAccount";
import { useAuthContext } from "../context/useAuthContext";

const Login = () => {
    const navigate = useNavigate();
    const { user, metadata } = useAuthContext();
    const { login, signup, logout, error, setError, setIsLoading, isLoading } = useAccount();
    const [view, setView] = useState("LOGIN");
    const [userData, setUserData] = useState({
        email: "",
        phoneNumber: "",
        password: "",
        accessCode: "2222",
    });

    useEffect(() => {
        if (user) {
            const currentTime = Date.now() / 1000;
            if (currentTime >= user.exp || !user.authorized) {
                logout();
                return;
            } else {
                navigate('/dashboard');
                return;
            }
        }
    }, []);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhoneNumber = (phoneNumber) => {
        const re = /^\+?[1-9]\d{1,14}$/;
        return re.test(String(phoneNumber));
    };

    const checkLocationPermission = () => {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject('Geolocation is not supported by your browser');
            } else {
                navigator.geolocation.getCurrentPosition(
                    (position) => resolve(position),
                    (error) => reject(error.message || 'Permission denied')
                );
            }
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        if (!validateEmail(userData.email)) {
            setError("Please enter a valid email address");
            setIsLoading(false)
            return;
        }
        try {
            const position = await checkLocationPermission();
            setError("Please wait...");

            if (metadata && metadata.ipData) {
                await login(userData, metadata);
            } else {
                setError("Location data not found");
            }
        } catch (err) {
            setError(err === 'Permission denied' ? 'Need to allow location permission' : err);
        }
        setIsLoading(false)
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        if (!validateEmail(userData.email)) {
            setError("Please enter a valid email address");
            setIsLoading(false)
            return;
        }
        if (!validatePhoneNumber(userData.phoneNumber)) {
            setError("Please enter a valid phone number");
            setIsLoading(false)
            return;
        }
        try {
            const position = await checkLocationPermission();
            setError("Please wait..."); 

            if (metadata && metadata.ipData) {
                await signup(userData, metadata);
            } else {
                setError("Location data not found");
            }
        } catch (err) {
            setError(err === 'Permission denied' ? 'Need to allow location permission' : err);
        }
        setIsLoading(false)
    };

    const handleUserChange = (param, value) => {
        setUserData({
            ...userData,
            [param]: value,
        });
    };

    const handleNumberInput = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        handleUserChange("phoneNumber", value);
    };

    return (
        <div className="new-login-section">
            <style>
                {`
                .form-control {
                        font-size: 26px;
                        height: 44px;
                    }
                `}
            </style>
            <div className="login-form-section">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="login-left-img">
                            <img src="assets/images/login-left.png" alt="img" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="new-login-form">
                            {view === "LOGIN" && 
                                <form onSubmit={handleLogin}>
                                    <h2 className="login-header">Welcome to <img src="assets/images/logo.svg" alt="logo" /></h2>
                                    <p>Please login to your account</p>
                                    <div className="login-input-item">
                                        <div className="input-group">
                                            <span className="left-img"><img src="assets/images/icon/user2.svg" alt="icon" /></span>
                                            <input className="form-control" type="email" placeholder="Enter your email..." onChange={(e) => handleUserChange("email", e.target.value)} value={userData.email} />
                                        </div>
                                    </div>
                                    <div className="login-input-item">
                                        <div className="input-group">
                                            <span className="left-img"><img src="assets/images/icon/lock.svg" alt="icon" /></span>
                                            <input className="form-control" type="password" placeholder="Enter your password..." onChange={(e) => handleUserChange("password", e.target.value)} value={userData.password} />
                                        </div>
                                    </div>
                                    <div className="remember-check">
                                        <Link onClick={() => setView("SIGNUP")} className="forget-pass">Don't have an account? Sign Up!</Link>
                                    </div>
                                    <button disabled={isLoading} className="btn theme-btn d-block w-100 mt-4">Login</button>
                                    {error && <div className="error">{error}</div>}
                                </form>
                            } 
                            {view === "SIGNUP" &&
                                <form onSubmit={handleSignup}>
                                    <h2 className="login-header">Welcome to <img src="assets/images/logo.svg" alt="logo" /></h2>
                                    <p>Please sign up for your account</p>
                                    <div className="login-input-item">
                                        <div className="input-group">
                                            <span className="left-img"><img src="assets/images/icon/user2.svg" alt="icon" /></span>
                                            <input className="form-control" type="email" placeholder="Enter your email..." onChange={(e) => handleUserChange("email", e.target.value)} value={userData.email} />
                                        </div>
                                    </div>
                                    <div className="login-input-item">
                                        <div className="input-group">
                                            <span className="left-img"><img src="assets/images/icon/lock.svg" alt="icon" /></span>
                                            <input className="form-control" type="password" placeholder="Enter your password..." onChange={(e) => handleUserChange("password", e.target.value)} value={userData.password} />
                                        </div>
                                    </div>
                                    <div className="login-input-item">
                                        <div className="input-group">
                                            <span className="left-img"><img src="assets/images/icon/lock.svg" alt="icon" /></span>
                                            <input className="form-control" type="tel" pattern="[0-9]*" inputMode="numeric" onInput={handleNumberInput} placeholder="Enter your phone number..." onChange={(e) => handleUserChange("phoneNumber", e.target.value)} value={userData.phoneNumber} />
                                        </div>
                                    </div>
                                    <div className="remember-check">
                                        <Link onClick={() => setView("LOGIN")} className="forget-pass">Already have an account? Log in!</Link>
                                    </div>
                                    <button disabled={isLoading} className="btn theme-btn d-block w-100 mt-4">Register</button>
                                    {error && <div className="error">{error}</div>}
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
