import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter} from 'react-router-dom'

import App from './App'
import AuthContextProvider from './context/AuthContext'
import DashboardContextProvider from './context/DashboardContext'
import "./assets/css/bootstrap.min.css"
import "./assets/css/style.css"


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <AuthContextProvider>
        <DashboardContextProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>      
        </DashboardContextProvider>
    </AuthContextProvider>
)
 