import { useState } from "react";
import { useApi } from "../hooks/useApi";
import { useFiles } from "../hooks/useFiles";

const FileReview = ({ threat, filesData }) => {
    const [selectedFile, setSelectedFile] = useState("");
    const [threatResponse, setThreatResponse] = useState("");
    const [isOCRProcessing, setIsOCRProcessing] = useState(false);
    const [isThreatChecking, setIsThreatChecking] = useState(false);
    const [isInvestigationChecking, setIsInvestigationChecking] = useState(false);
    const [message, setMessage] = useState("");
    const { updateFile } = useFiles();
    const { scanOCR, detectFileThreats, detectThreats } = useApi();



    const handleFileChange = (e) => {
        setSelectedFile(e.target.value);
        setMessage("");
    };

    const handleFileOCRSubmit = async () => {
        if (!selectedFile) {
            setMessage("Please choose a file first.");
            return;
        }

        setIsOCRProcessing(true);
        try {
            const json = await scanOCR(selectedFile);
            updateFile(selectedFile, { extractedText: json.data.OCRText, ocrPositionalData: json.data.positionalData, acceptedText: json.data.OCRText });
        } finally {
            setIsOCRProcessing(false);
        }
    };

    const handleDetectFileThreat = async () => {
        if (!selectedFile) {
            setMessage("Please choose a file first.");
            return;
        }

        setIsThreatChecking(true);
        try {
            const json = await detectFileThreats(selectedFile);
            if (json.data.threatFound) {
                setThreatResponse("Threat found and reported!");
            } else {
                setThreatResponse("No threat found!");
            }
        } finally {
            setIsThreatChecking(false);
        }
    };

    const handleDetectThreat = async () => {
        if (!threat.files || threat.files.length === 0) {
            setMessage("No files associated with this threat.");
            return;
        }

        setIsInvestigationChecking(true);
        try {
            const json = await detectThreats(threat.files);
            if (json.data && json.data.threatFound) {
                setThreatResponse("Threat found and reported!");
            } else {
                setThreatResponse("No threat found!");
            }
        } finally {
            setIsInvestigationChecking(false);
        }
    };

    return (
        <div style={{ margin: 20 }}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><h1 className="upload-heading">FILE REVIEW</h1></div>
            <div style={{ margin: 20 }}>
                <label htmlFor="fileSelect">Select a File:</label>
                <select id="fileSelect" value={selectedFile} onChange={handleFileChange}>
                    <option value="" disabled>Select a File</option>
                    {Object.values(filesData).map((file) => (
                        <option key={file.id} value={file.id}>{file.filename}</option>
                    ))}
                </select>
                <button onClick={handleFileOCRSubmit} disabled={isOCRProcessing}>
                    {isOCRProcessing ? "Processing..." : "Submit File for OCR"}
                </button>
                <button onClick={handleDetectFileThreat} disabled={isThreatChecking}>
                    {isThreatChecking ? "Checking for Threats..." : "Check File for Threats"}
                </button>
                <button onClick={handleDetectThreat} disabled={isInvestigationChecking}>
                    {isInvestigationChecking ? "Checking Investigation for Threats..." : "Check Investigation for Threats"}
                </button>

                {message && <div style={{ color: 'red' }}>{message}</div>}
                {threatResponse}
            </div>
            {filesData && filesData[selectedFile] && filesData[selectedFile].acceptedText && (
                <div>
                    <h1>OCR Text</h1>
                    <div>{filesData[selectedFile].acceptedText}</div>
                </div>
            )}
        </div>
    );
};

export default FileReview;
