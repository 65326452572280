import { useAccount } from './useAccount'


export const useFiles = () => {
    const { checkLogout} = useAccount()
    const uploadFiles = async(formData)=> {
        checkLogout()
        try {
            const response = await fetch(`/files/uploadMultiple`, {
                method: 'POST',
                body: formData
            })
            const json = await response.json()
            return json
          } catch (error) {
          }
    }

    const getFilesByIds = async(fileIDs)=> {
        checkLogout()
        try {
            const response = await fetch(`/files/search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify({ fileIDs }) 
            })
            return await response.json()
          } catch (error) {
          }
    }

    const getAllFiles = async (page, limit) => {
        checkLogout()
        try {
            const response = await fetch(`/files?page=${page}&limit=${limit}`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json'
                }
            });
            return await response.json();
        } catch (error) {
        }
    };
    

    const updateFile = async (fileId, fileData) => {
        checkLogout()
      const response = await fetch(`/files/${fileId}`, {
          method: 'PATCH',
          headers: { 
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(fileData)
      })
      return await response.json()
  }


    return {uploadFiles, getFilesByIds, getAllFiles, updateFile}
}

