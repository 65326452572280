import { useAccount } from './useAccount'

export const useUsers = () => {
    const { checkLogout} = useAccount()
    
    const updateUserById = async (userId, userData) => {
        checkLogout()
        const response = await fetch(`/users/update/${userId}`, {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({userData})
        })
        return await response.json()
    }

    const getUserById = async (userId) => {
        checkLogout()
        const response = await fetch(`/users/${userId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        return await response.json()
    }

    const getMapUsers = async () => {
        checkLogout()
        const response = await fetch(`/users/map`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        return await response.json()
    }

    const getExp = async (userId) => {
        checkLogout()
        const response = await fetch(`/users/exp/${userId}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json'
            }
        })
        return await response.json()
    }

    return {updateUserById, getUserById, getMapUsers, getExp}
}

