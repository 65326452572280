import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './EmailWellnessCheck.css';
import { useProfiles } from '../hooks/useProfiles';

const EmailWellnessCheck = () => {
    const { token } = useParams();
    const { confirmWellnessEmail } = useProfiles();
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
        try {
            const json = await confirmWellnessEmail(token);
            setResponse(json);
            setLoading(false);
            if (!json.ok) {
            setError(json.message);
            }
        } catch (err) {
            setError('An error occurred while fetching data.');
            setLoading(false);
        }
        };
        fetchData();
    }, []);

    if (loading) {
        return <div className="email-wellness-loading">Loading...</div>;
    }

    return (
        <div className="email-wellness-verification-container">
            <h1 className="email-wellness-center-content">
                {response && response.message}
            </h1>
        </div>
    );
};

export default EmailWellnessCheck;
