import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../context/useAuthContext';
import { useAccount } from '../hooks/useAccount';
import { useProfiles } from '../hooks/useProfiles';
import { Link, useNavigate } from 'react-router-dom';
import EmergencyForm from './EmergencyForm';
import './Navbar.css';
import { useThreats } from '../hooks/useThreats';
import { useUsers } from '../hooks/useUsers';

const Navbar = () => {
    const { logout, sendPanicAlert} = useAccount();
    const { updateUserById, getExp} = useUsers()
    const { sendPanicThreat} = useThreats()
    const { user, dispatch } = useAuthContext();
    const { getProfile} = useProfiles();
    const [profile, setProfile] = useState(null);
    const [emergencyFormVisible, setEmergencyFormVisible] = useState(false);
    const [panicType, setPanicType] = useState("")
    const navigate = useNavigate()

    useEffect(() => {

        if (user && user.authorized) {
            const currentTime = Date.now() / 1000;
            if (currentTime >= user.exp) {
                logout();
                return;
            }

            const fetchProfile = async () => {
                try {
                    const json = await getProfile();
                    setProfile(json.data.profile);
                } catch (error) {
                    console.error('Failed to fetch profile:', error);
                }
            };
            fetchProfile();

            
        }
    }, [user]);

    const handleClick = () => {
        logout();
    };

    const panic = async (panicType) => {
        setPanicType(panicType)
        await sendPanicAlert(panicType);
        setEmergencyFormVisible(true);
    };

    const handleEmergencyFormSubmit = async (formData) => {
        await sendPanicThreat(formData);
        setEmergencyFormVisible(false);
    };

    const handleEmergencyFormClose = () => {
        setEmergencyFormVisible(false);
    };

    const handleBlockUser = () => {
        updateUserById(user.id, { "accountBlocked": 1, "refreshExp": 0, "authorized": 0 });
}

    return (
        <div className="header-section">
            <div className="mobile-menu-opener"><img src="https://www.svgrepo.com/show/378722/three-line-horizontal.svg" alt="icon" /></div>
            <div className='header-wrapper'>
                <h1 className='header-logo'><img onClick={()=>navigate('/')} src="/assets/images/seethreat.jpg" alt="logo" /></h1>
                {user && user.authorized && 
                    <div style={{display: 'flex'}}>
                        <button onClick={() => panic("watch")} className='btn theme-btn'>PANIC WATCH</button>
                        <button style={{marginLeft: 20}} onClick={() => panic("warning")} className='btn theme-btn'>PANIC WARNING</button>
                        <button style={{marginLeft: 20}} onClick={() => handleBlockUser()} className='btn theme-btn'>BLOCK ACCESS</button>
                    </div>
                    
                    }
                {user && user.authorized &&
                <div className='header-menu'>
                    <ul>
                        <li>Expires: {user.exp ? (<div className='d-inline'>{new Date(user.exp * 1000).toLocaleString()}</div>) : ""}</li>
                        <li>{"Role: " + user.role}</li>
                        {profile && profile.groupName &&
                        <li>{"Department: " + profile.groupName}</li>
                        }
                        <li><img style={{height: 15, width: 15}} src="https://cdn-icons-png.flaticon.com/512/1946/1946429.png" alt="user" />{user.username}</li>
                        <li>
                            <Link to="/" onClick={handleClick}>
                                <img style={{height: 15, width: 15}} src="https://cdn-icons-png.flaticon.com/512/126/126467.png" alt="user" /> Logout
                            </Link>
                        </li>
                    </ul>
                </div>}
            </div>
            {emergencyFormVisible && 
                <EmergencyForm 
                    onSubmit={handleEmergencyFormSubmit} 
                    onClose={handleEmergencyFormClose} 
                    panicType={panicType}
                />
            }
        </div>
    );
}

export default Navbar;
