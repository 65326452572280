import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/useAuthContext';

const Sidebar = ({ buttons, isCollapsed}) => {
	const { user } = useAuthContext() 
	const navigate = useNavigate();

	if (!user) {
		return <div></div>
	}

	return (
		<div className={`sidebar-section ${isCollapsed ? 'collapsed' : ''}`}>
		<div className="sidebar-wrapper">
			<ul>
			{buttons.map((buttonData, idx) => {
				if (user.role === "user" && buttonData.role !== user.role) {
					return <div></div>
				}
				return <li key={idx+buttonData.label}>
				<button onClick={() => navigate(buttonData.redirectTo)}>
					{buttonData.label}
				</button>
				</li>
			})}
			</ul>
		</div>
		</div>
	);
};

export default Sidebar;
