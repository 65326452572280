import React, { useEffect, useState } from 'react';
import { useFiles } from '../hooks/useFiles';
import Popup from '../components/Popup';
import './AllFiles.css';
import { useApi } from '../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/useAuthContext';
import { useAccount } from '../hooks/useAccount';
import { eventURL } from '../definitions';

const AllFiles = () => {
    const navigate = useNavigate();
    const { user, dispatch } = useAuthContext();
    const { logout } = useAccount();
    const [files, setFiles] = useState([]);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loadingOCR, setLoadingOCR] = useState({});
    const [loadingThreat, setLoadingThreat] = useState({});
    const { updateFile } = useFiles();
    const { scanOCR, detectFileThreats } = useApi();

    useEffect(() => {
        let eventSource;
        let userEventSource;

        if (!user || !user.authorized) {
            logout();
            return;
        }
        const currentTime = Date.now() / 1000;
        if (currentTime >= user.exp) {
            logout();
            return;
        }

        const fetchFiles = () => {
            eventSource = new EventSource(`${eventURL}/files/?page=${currentPage}&limit=5`);


            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.error) {
                    console.error('Failed to fetch files:', data.message);
                    return;
                }
                setFiles(data.data.files);
                setTotalPages(data.data.totalPages);
                setInitialLoading(false);
            };

            eventSource.onerror = (error) => {
                console.error('EventSource failed:', error);
                eventSource.close();
            };
        };

        fetchFiles();

        userEventSource = new EventSource(`${eventURL}/users/exp/sse/${user.id}`);


        userEventSource.onmessage = (event) => {
            const userjson = JSON.parse(event.data);
            if (Date.now() / 1000 > userjson.refreshExp) {
                const newUser = { ...user, exp: userjson.refreshExp };
                dispatch({ type: "UPDATE_USER", payload: newUser });
            }
        };

        userEventSource.onerror = (error) => {
            userEventSource.close();
        };

        window.onbeforeunload = () => {
            eventSource.close();
            userEventSource.close();
        };

        return () => {
            if (eventSource) {
                eventSource.close();
            }
            if (userEventSource) {
                userEventSource.close();
            }
        };
    }, [currentPage, user]);

    if (!files.length) {
        return <div>Loading Files...</div>;
    }

    const handleFileClick = (file) => {
        const fileExtension = file.path.split('.').pop().toLowerCase();
        const formattedFile = file.path.replace(/\\/g, '/');
        if (['jpg', 'jpeg', 'png', 'gif', 'pdf'].includes(fileExtension)) {
            setSelectedFile(formattedFile);
            setIsPopupVisible(true);
        } else if (['doc', 'docx'].includes(fileExtension)) {
            const link = document.createElement('a');
            link.href = formattedFile;
            link.download = file.filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            const link = document.createElement('a');
            link.href = formattedFile;
            link.download = file.filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const getFileTypeIcon = (fileType) => {
        switch (fileType) {
            case 'video':
                return "https://cdn.iconscout.com/icon/free/png-512/free-mp4-file-26-521773.png?f=webp&w=512";
            case 'audio':
                return "https://cdn.iconscout.com/icon/free/png-512/free-music-file-8378475-6770049.png?f=webp&w=512";
            case 'image':
                return "https://cdn.iconscout.com/icon/free/png-512/free-gallery-7394876-6066632.png?f=webp&w=512";
            case 'doc':
                return "https://cdn.iconscout.com/icon/free/png-512/free-doc-file-1934509-1634559.png?f=webp&w=512";
            default:
                return "https://icons.veryicon.com/png/o/file-type/system-icon/unknown-file-types.png";
        }
    };

    const handleDetectFileThreat = async (id) => {
        setLoadingThreat((prev) => ({ ...prev, [id]: true }));
        try {
            const json = await detectFileThreats(id);
        } catch (error) {
        } finally {
            setLoadingThreat((prev) => ({ ...prev, [id]: false }));
        }
    };

    const handleFileOCRSubmit = async (file) => {
        setLoadingOCR((prev) => ({ ...prev, [file.id]: true }));
        try {
            const json = await scanOCR(file.id);
            await updateFile(file.id, { extractedText: json.data.OCRText, ocrPositionalData: json.data.positionalData, acceptedText: json.data.OCRText });
        } catch (error) {
        } finally {
            setLoadingOCR((prev) => ({ ...prev, [file.id]: false }));
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="files-container">
            {initialLoading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <table className="files-table">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Filename</th>
                                <th>Uploaded Date</th>
                                <th>Accepted Text</th>
                                <th>Actions</th>
                                <th>Threat</th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.map(file => (
                                <tr key={file.id}>
                                    <td>
                                        <img 
                                            src={getFileTypeIcon(file.fileType)} 
                                            alt={file.fileType} 
                                            style={{ width: 40, height: 40, cursor: 'pointer' }} 
                                            onClick={() => handleFileClick(file)}
                                        />
                                    </td>
                                    <td>{file.filename}</td>
                                    <td>{new Date(file.uploadDate).toLocaleString()}</td>
                                    <td>{file.acceptedText}</td>
                                    <td>
                                        <div style={{ display: "flex" }}>
                                            <button onClick={() => handleFileOCRSubmit(file)} disabled={loadingOCR[file.id] || file.acceptedText}>
                                                {loadingOCR[file.id] ? "Processing..." : "OCR"}
                                            </button>
                                            <button onClick={() => handleDetectFileThreat(file.id)} disabled={loadingThreat[file.id] || file.threatDetected || !file.acceptedText}>
                                                {loadingThreat[file.id] ? "Detecting..." : "Find Threat"}
                                            </button>
                                        </div>
                                    </td>
                                    <td onClick={() => navigate(`/threat/${file.threatRefId}`)}>
                                        {file.threatDetected && <span className={`bubble red`}>Threat found!</span>}
                                        {!file.threatDetected && <div>No threat!</div>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                disabled={currentPage === index + 1}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            )}
            {isPopupVisible && <Popup file={selectedFile} onClose={() => setIsPopupVisible(false)} />}
        </div>
    );
};

export default AllFiles;
