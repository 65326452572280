import React, { useState, useRef } from 'react';
import { useFiles } from '../hooks/useFiles';

function DatabaseUpload() {
    const { uploadFiles } = useFiles();
    const [files, setFiles] = useState([{ idx: 0, file: null }]);
    const [loading, setLoading] = useState(false);
    const fileInputRefs = useRef([]);

    const handleFileChange = (e, idx) => {
        const newFiles = files.map(fileObj =>
            fileObj.idx === idx ? { ...fileObj, file: e.target.files[0] } : fileObj
        );
        setFiles(newFiles);
    };

    const handleAddFile = () => {
        setFiles([...files, { idx: files.length ? files[files.length - 1].idx + 1 : 0, file: null }]);
    };

    const handleRemoveFile = (idx) => {
        setFiles(files.filter(fileObj => fileObj.idx !== idx));
        fileInputRefs.current = fileInputRefs.current.filter((_, index) => index !== idx);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        files.forEach(fileObj => {
            if (fileObj.file) {
                formData.append('files', fileObj.file);
            }
        });

        await uploadFiles(formData);
        setLoading(false);
        setFiles([{ idx: 0, file: null }]);
        fileInputRefs.current.forEach(input => {
            if (input) input.value = '';
        });
    };

    return (
        <div style={{ margin: 20 }}>
            {"Files accepted: .pdf, .doc, .docx, .jpg, .jpeg, .png"}
            <form onSubmit={handleSubmit}>
                <button type="button" onClick={handleAddFile} disabled={loading}>Add another file</button>
                {files.map((fileObj, index) => (
                    <div key={fileObj.idx}>
                        <input
                            type="file"
                            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.mp4,.mov,.avi,.mkv,.mp3,.wav,.ogg,.webm"
                            onChange={(e) => handleFileChange(e, fileObj.idx)}
                            disabled={loading}
                            ref={el => fileInputRefs.current[index] = el}
                        />
                        {fileObj.idx > 0 &&
                            <button type="button" onClick={() => handleRemoveFile(fileObj.idx)}>
                                X
                            </button>}
                    </div>
                ))}
                <button type="submit" disabled={loading}>{loading ? 'Uploading...' : 'Upload'}</button>
            </form>
        </div>
    );
}

export default DatabaseUpload;
