import React, { useState } from "react";
import { jsPDF } from "jspdf";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useFiles } from "../hooks/useFiles";

const formatKey = (key) => {
    return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
};

const GenerateReport = ({ threat }) => {
    const { getFilesByIds } = useFiles();
    const [isGenerating, setIsGenerating] = useState(false);
    const [isDownloadReady, setIsDownloadReady] = useState(false);
    const [zipFile, setZipFile] = useState(null);

    const handleGenerateReport = async () => {
        setIsGenerating(true);

        const doc = new jsPDF();
        let yOffset = 10;

        doc.text(`Title: ${threat.title}`, 10, yOffset);
        yOffset += 10;
        doc.text(`Description: ${threat.description}`, 10, yOffset);
        yOffset += 10;
        doc.text(`Incident Time: ${new Date(threat.incidentTime).toLocaleString()}`, 10, yOffset);
        yOffset += 10;
        doc.text(`Threat Type: ${threat.threatType}`, 10, yOffset);
        yOffset += 10;
        doc.text(`Severity: ${threat.severity}`, 10, yOffset);
        yOffset += 10;
        doc.text(`Reporting Officer: ${threat.username}`, 10, yOffset);
        yOffset += 10;

        if (threat.threatSpecificData && Object.keys(threat.threatSpecificData).length > 0) {
        doc.text(`Relevant Information:`, 10, yOffset);
        yOffset += 10;
        Object.entries(threat.threatSpecificData).forEach(([key, value]) => {
            doc.text(`${formatKey(key)}: ${value}`, 10, yOffset);
            yOffset += 10;
        });
        }

        doc.text(`History:`, 10, yOffset);
        yOffset += 10;
        threat.history.forEach((update, idx) => {
        doc.text(
            `- ${update.description} by ${update.username} on ${new Date(update.updatedOn).toLocaleString()}`,
            10,
            yOffset
        );
        yOffset += 10;
        });

        const fileIds = threat.files || [];
        const zip = new JSZip();
        zip.file(`${threat.id}.pdf`, doc.output("blob"));

        if (fileIds.length > 0) {
        try {
            const filesJson = await getFilesByIds(fileIds);
            const filesData = Object.values(filesJson.data.files);

            for (const file of filesData) {
            const response = await fetch(file.path); 
            if (!response.ok) {
                throw new Error(`Failed to fetch ${file.filename}`);
            }
            const blob = await response.blob();
            zip.file(file.filename, blob);
            }

            const content = await zip.generateAsync({ type: "blob" });
            setZipFile(content);
        } catch (error) {
            setIsGenerating(false);
            return;
        }
        } else {
        const content = await zip.generateAsync({ type: "blob" });
        setZipFile(content);
        }

        setIsGenerating(false);
        setIsDownloadReady(true);
    };

    const handleDownloadZip = () => {
        saveAs(zipFile, `${threat.title}_report.zip`);
    };

    return (
        <div>
        <button
            style={{
            height: "3dvh",
            width: "10dvw",
            backgroundColor: "lightgreen",
            borderRadius: "2dvh",
            fontSize: "1dvh"
            }}
            onClick={handleGenerateReport}
            disabled={isGenerating}
        >
            {isGenerating ? "Generating..." : "Generate Report"}
        </button>
        {isDownloadReady && (
            <button
            style={{
                height: "3dvh",
                width: "10dvw",
                backgroundColor: "lightgray",
                borderRadius: "2dvh",
                fontSize: "1dvh"
            }}
            onClick={handleDownloadZip}
            >
            Download Report
            </button>
        )}
        </div>
    );
};

export default GenerateReport;
