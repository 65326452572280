import React from 'react';

const Popup = ({ file, onClose }) => {
    if (!file) return null;

    const fileExtension = file.split('.').pop().toLowerCase();
    const formattedFile = file.replace(/\\/g, '/'); 

    const renderContent = () => {
        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
        return (
            <div style={imageContainerStyles}>
            <img src={formattedFile} alt="Uploaded File" style={imageStyles} />
            </div>
        );
        } else if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
        return <video src={formattedFile} controls style={videoStyles} />;
        } else if (['mp3', 'wav', 'ogg'].includes(fileExtension)) {
        return <audio src={formattedFile} controls style={audioStyles} />;
        } else if (fileExtension === 'pdf') {
        return (
            <iframe src={formattedFile} style={iframeStyles} title="PDF Viewer" />
        );
        } else if (['doc', 'docx'].includes(fileExtension)) {
        return (
            <a href={formattedFile} download style={downloadLinkStyles}>
                Click to download
            </a>
        );
        } else {
        return <div>Unsupported file type</div>;
        }
    };

    const popupStyles = {
        position: 'relative',
        backgroundColor: '#fff',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: ['pdf'].includes(fileExtension) ? '80%' : 'auto',
        height: ['pdf'].includes(fileExtension) ? '80%' : 'auto',
        maxWidth: '80%',
        maxHeight: '80%',
        overflow: 'hidden',
    };

    return (
        <div style={overlayStyles} onClick={onClose}>
        <div style={popupStyles} onClick={(e) => e.stopPropagation()}>
            <button onClick={onClose} style={closeButtonStyles}>X</button>
            <div style={contentStyles}>
            {renderContent()}
            </div>
        </div>
        </div>
    );
};

const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
};

const closeButtonStyles = {
    position: 'absolute',
    top: 10,
    right: 10,
    background: 'none',
    border: 'none',
    fontSize: 24,
    cursor: 'pointer',
    zIndex: 1001
};

const contentStyles = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
};

const imageContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto', 
    maxWidth: '100%',
    maxHeight: '100%'
};

const imageStyles = {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
};

const videoStyles = {
    maxWidth: '100%',
    maxHeight: '100%'
};

const audioStyles = {
    width: '100%'
};

const iframeStyles = {
    width: '100%',
    height: '100%',
    border: 'none'
};

const downloadLinkStyles = {
    fontSize: '18px',
    textDecoration: 'none',
    color: '#007bff'
};

export default Popup;
